import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ISO_DATE_FORMAT } from "legacy/constants/WidgetValidation";
import { DatePickerType } from "legacy/widgets/DatePickerWidget/Constants";
import DatePickerComponent from "legacy/widgets/DatePickerWidget/DatePickerComponent";
import { CompactMode, EditProps } from "../Constants";
import { DateCellWrapper, Position } from "./Shared";
import type { TableCellProps } from "../TableUtilities";

const EditDateCell = (props: {
  editProps: EditProps;
  value: any;
  inputPosition: Position;
  compactMode: CompactMode;
  cellProps: TableCellProps;
}) => {
  const { value, editProps, inputPosition } = props;
  const { handleEditStop, editMinDate, editMaxDate, editTwentyFourHourTime } =
    editProps;

  const {
    inputFormat,
    outputFormat,
    timezone,
    manageTimezone,
    displayTimezone,
  } = props.cellProps;

  const [date, setDate] = useState<string>(value);
  const [closed, setClosed] = useState<boolean>(false);

  const handleDateChange = useCallback(
    (date: string) => {
      setDate(date);
    },
    [setDate],
  );

  const handleDatepickerClose = useCallback(() => {
    // the blueprint popover click event will fire the close handler, then the blueprint datepicker itself
    // fires its close handler, so we need to prevent the second one from calling handleEditStop
    // probably because blueprint is doing a poor job of handling internal close state
    if (closed) return;

    setClosed(true);
    handleEditStop({ shouldSave: true, value: date, validationErrors: [] });
  }, [handleEditStop, closed, date]);

  useEffect(() => {
    return () => {
      // stop editing w/o saving when component unmounts
      handleEditStop({ shouldSave: false });
    };
    // not necessary because the input will no longer be rendered once editing stops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedMinDate = useMemo(() => {
    if (editMinDate) {
      return moment(editMinDate, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT);
    }
  }, [editMinDate]);

  const formattedMaxDate = useMemo(() => {
    if (editMaxDate) {
      return moment(editMaxDate, ISO_DATE_FORMAT).format(ISO_DATE_FORMAT);
    }
  }, [editMaxDate]);

  return (
    <DateCellWrapper
      $position={inputPosition}
      $compactMode={props.compactMode}
      cellProperties={props.cellProps.cellProperties}
    >
      <DatePickerComponent
        label={""}
        dateFormat={inputFormat}
        displayDateFormat={outputFormat}
        widgetId={"unused"}
        datePickerType={DatePickerType.DATE_PICKER}
        onDateSelected={handleDateChange}
        isLoading={false}
        isDisabled={false}
        selectedDate={date}
        forceOpen={true}
        onDatePickerClosed={handleDatepickerClose}
        showCalendarIcon={false}
        twentyFourHourTime={editTwentyFourHourTime ?? true}
        minDate={formattedMinDate}
        maxDate={formattedMaxDate}
        timezone={manageTimezone ? timezone : undefined}
        displayTimezone={manageTimezone ? displayTimezone : undefined}
        showTimezone={false}
      />
    </DateCellWrapper>
  );
};

export default EditDateCell;
