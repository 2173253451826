import { call } from "redux-saga/effects";
import SigningKeyRotationApi from "legacy/api/SigningKeyRotationApi";
import { sendErrorUINotification } from "utils/notification";
import { createSaga } from "../../../utils/saga";
import slice from "../slice";

export function* startKeyRotationsInternal() {
  let resp: {
    responseMeta: { status: number; message: string; success: boolean };
    id?: string;
  };
  try {
    resp = yield call(SigningKeyRotationApi.startRotation);
    if (resp?.id === undefined) {
      throw new Error(resp?.responseMeta?.message);
    }
  } catch (e: any) {
    sendErrorUINotification({
      message: e?.message ? e.message : `Error starting resource signing`,
    });
    return;
  }
  return { id: resp.id };
}

export const startKeyRotation = createSaga(
  startKeyRotationsInternal,
  "startKeyRotation",
  {
    sliceName: "agents",
  },
);

slice.saga(startKeyRotation, {
  start(state, { payload }) {
    delete state.errors.keyRotations;
  },
  success(state, { payload }) {
    delete state.errors.keyRotations;
  },
  error(state, { payload }) {
    state.errors.keyRotations = { error: payload };
  },
});
