import React, { useEffect } from "react";
import { iframeMessageHandler } from "utils/iframe";

/**
 * Calls @param onClickOutside when the user clicks outside of a set of elements
 *
 * @param  - `wrapperRefs, wrapperIds, wrapperSelectors` - Arrays of refs to DOM elements that should be considered "wrappers" for
 * the click outside handler. If the user clicks outside of all of these elements, the callback will be
 * called.
 * @param - onClickOutside - A callback function that will be called when the user clicks outside of the wrappers
 */
export function usePointerDownOutside({
  wrapperRefs,
  wrapperIds,
  wrapperSelectors,
  onClickOutside,
}: {
  wrapperRefs?: Array<React.MutableRefObject<Element | null | undefined>>;
  wrapperIds?: Array<string>;
  wrapperSelectors?: Array<string>;
  onClickOutside: () => void;
}): void {
  useEffect((): (() => void) => {
    const handlePointerDown = (e: MouseEvent): void => {
      const target = e.target as Node;
      const clickedOutsideWrappers = [
        ...(wrapperRefs || []).map((ref) => {
          if (ref.current) {
            return !ref.current.contains(target);
          }
          return true;
        }),
        ...(wrapperSelectors || []).map((selector) => {
          const wrapperNodes = document.querySelectorAll(selector);
          if (!wrapperNodes) return true;

          for (let i = 0; i < wrapperNodes.length; i++) {
            if (wrapperNodes[i].contains(target)) {
              return false;
            }
          }

          return true;
        }),
        ...(wrapperIds || []).map((elementId) => {
          const wrapper = document.getElementById(elementId);
          if (wrapper) {
            return !wrapper.contains(target);
          }
          return true;
        }),
      ].filter((result) => result);

      // Only call the callback if we've clicked outside *all*
      // of the possible wrappers we passed in
      if (
        clickedOutsideWrappers.length ===
        (wrapperRefs || []).length +
          (wrapperIds || []).length +
          (wrapperSelectors || []).length
      ) {
        onClickOutside();
      }
    };
    const iframeClickEventListener = () => {
      onClickOutside();
    };

    window.addEventListener("pointerdown", handlePointerDown);
    window.addEventListener("contextmenu", handlePointerDown);
    iframeMessageHandler.addEventListener("click", iframeClickEventListener);

    return (): void => {
      window.removeEventListener("pointerdown", handlePointerDown);
      window.removeEventListener("contextmenu", handlePointerDown);
      iframeMessageHandler.removeEventListener(
        "click",
        iframeClickEventListener,
      );
    };
  }, [wrapperRefs, wrapperIds, wrapperSelectors, onClickOutside]);
}
