import { Agent, Organization } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { controlFlowOverrideFlagSelector } from "store/slices/apisShared/selectors/controlFlowEnabledDynamic";
import { EntitiesStateWithMeta, ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { AgentWithHealth, getAgents, probeAgents } from "../client";
import slice, { AgentMeta } from "../slice";

export interface GetAgentsPayload {
  organization: Organization;
  /**
   * True: Browser will healthcheck OPAs and Cloud agents of the organization.
   * False: Browser will only healthcheck either OPAs or Cloud agents based on the organization setting.
   */
  probeAll?: boolean;

  /**
   * If agents are provided, the saga will not fetch agents from the server.
   */
  agents?: Agent[];
}

function* getAgentsInternal({
  organization,
  probeAll = false,
  agents,
}: GetAgentsPayload) {
  const controlFlowOverrideEnabled: boolean = yield select(
    controlFlowOverrideFlagSelector,
  );

  if (agents) {
    const agentWithHealths: AgentWithHealth[] = yield call(
      probeAgents,
      agents,
      organization,
      probeAll,
      controlFlowOverrideEnabled,
    );
    return agentWithHealths;
  } else {
    const agentWithHealths: AgentWithHealth[] = yield call(
      getAgents,
      organization,
      probeAll,
      controlFlowOverrideEnabled,
    );
    return agentWithHealths;
  }
}

export const getAgentsSaga = createSaga(getAgentsInternal, "getAgentsSaga", {
  sliceName: "agents",
});

slice.saga(getAgentsSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = {
      ...state?.meta?.[ROOT],
      organizationId: payload.organization.id,
    };
    delete state.errors[ROOT];
  },
  success(state, { payload }) {
    modifyState(state, payload);
    delete state.loading[ROOT];
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

const modifyState = (
  state: EntitiesStateWithMeta<AgentWithHealth, AgentMeta>,
  payload: AgentWithHealth[],
) => {
  state.entities = payload.reduce(
    (result, agentWithHealth) => {
      result[agentWithHealth.agent.id] = agentWithHealth;
      return result;
    },
    {} as Record<string, AgentWithHealth>,
  );
};
