import { Typographies } from "@superblocksteam/shared";

export enum DatePickerType {
  DATE_PICKER = "DATE_PICKER", // date + time (legacy)
  DATE_RANGE_PICKER = "DATE_RANGE_PICKER",
}

export const DEFAULT_DATEPICKER_LABEL_STYLE_VARIANT: keyof Typographies =
  "inputLabel";
export const DEFAULT_DATEPICKER_INPUT_STYLE_VARIANT: keyof Typographies =
  "inputText";
