import { put } from "redux-saga/effects";
import { EntitiesErrorType } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import slice from "../slice";
import { ApiDto } from "../types";
import { persistApiSaga } from "./persistApi";

function* updateApi(updated: ApiDto): Generator<any, void, any> {
  yield put(persistApiSaga.start.create({ api: updated }));
  // Do not return anything
}

export const updateApiSaga = createSaga(updateApi, "updateApiSaga", {
  sliceName: "apis",
});

slice.saga(updateApiSaga, {
  start(state, { payload }) {
    state.meta[payload.id] = state.meta[payload.id] ?? {};
    state.meta[payload.id].dirty = true;
    state.meta[payload.id].needsBindingExtraction = true;
    state.entities[payload.id] = payload;
    delete state.errors[payload.id];
  },
  success(state, { meta }) {
    // clear API outputs for any steps that may have been deleted
    const curOutputs = Object.keys(
      state.meta[meta.args.id].executionResult?.context?.outputs ?? {},
    );
    const newOutputs = Object.values(meta.args.actions?.actions ?? {}).map(
      (action) => action.name,
    );
    if (curOutputs.length === newOutputs.length) {
      return;
    }

    for (const name of curOutputs) {
      if (!newOutputs.includes(name)) {
        delete state.meta[meta.args.id].executionResult?.context.outputs[name];
      }
    }
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.id] = {
      type: EntitiesErrorType.SAVE_ERROR,
      error: payload,
    };
  },
});
