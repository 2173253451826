import {
  AuthId,
  ConnectedUserTokenDto,
  TokenScope,
} from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { EntitiesStateWithMeta } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { getSharedConnectedTokens, getUserConnectedTokens } from "../client";
import slice, { DatasourceDtoViews, DatasourceMeta } from "../slice";
import { connectedTokensKey } from "../utils";

interface GetConnectedTokensPayload {
  integrationId: string;
  integrationConfigurationId: string;
  authId: AuthId;
  tokenScope: TokenScope;
}

function* getConnectedTokensInternal({
  integrationId,
  integrationConfigurationId,
  authId,
  tokenScope,
}: GetConnectedTokensPayload) {
  const connectedUserTokens: ConnectedUserTokenDto[] =
    tokenScope === TokenScope.DATASOURCE
      ? yield call(
          getSharedConnectedTokens,
          integrationId,
          integrationConfigurationId,
          authId,
        )
      : yield call(
          getUserConnectedTokens,
          integrationId,
          integrationConfigurationId,
          authId,
        );
  return connectedUserTokens;
}

export const getConnectedTokensSaga = createSaga(
  getConnectedTokensInternal,
  "getConnectedTokensSaga",
  {
    sliceName: "datasources",
  },
);

slice.saga(getConnectedTokensSaga, {
  start(state, { payload }) {
    state.loading[connectedTokensKey(payload.integrationConfigurationId)] =
      true;
    delete state.errors[connectedTokensKey(payload.integrationConfigurationId)];
  },
  success(state, { payload, meta }) {
    modifyState(
      state,
      payload,
      meta.args.integrationId,
      meta.args.integrationConfigurationId,
    );
    delete state.loading[
      connectedTokensKey(meta.args.integrationConfigurationId)
    ];
  },
  error(state, { payload, meta }) {
    state.errors[connectedTokensKey(meta.args.integrationConfigurationId)] = {
      error: payload,
    };
    delete state.loading[
      connectedTokensKey(meta.args.integrationConfigurationId)
    ];
  },
});

const modifyState = (
  state: EntitiesStateWithMeta<DatasourceDtoViews, DatasourceMeta>,
  payload: ConnectedUserTokenDto[],
  integrationId: string,
  integrationConfigurationId: string,
): void => {
  if (!state.meta[integrationId]) {
    state.meta[integrationId] = {};
  }

  const metadata = state.meta[integrationId].metadata || {};
  state.meta[integrationId].metadata = metadata;

  const connectedUserTokens: Record<string, ConnectedUserTokenDto[]> =
    state.meta[integrationId].metadata?.connectedUserTokens || {};
  connectedUserTokens[integrationConfigurationId] = [];
  metadata.connectedUserTokens = connectedUserTokens;

  payload.forEach((token) => {
    if (!connectedUserTokens[token.integrationConfigurationId]) {
      connectedUserTokens[token.integrationConfigurationId] = [];
    }
    connectedUserTokens[token.integrationConfigurationId].push(token);
  });
};
