const derived = {
  getValidationErrors: (props, moment, _) => {
    const errors = {
      required: undefined,
      minMax: undefined,
      customRule: undefined,
      email: undefined,
      number: undefined,
      url: undefined,
    };
    const text = props.text ?? "";
    if (props.isRequired && (!text || !text.length)) {
      errors.required = "This field is required";
    }

    if (
      props.customValidationRule &&
      props.customValidationRule.length &&
      props.customValidationRule === "false"
    ) {
      errors.customRule = props.customErrorMessage || "Invalid input";
    }

    if (props.inputType === "TEXT" || props.inputType === "PASSWORD") {
      const maxLengthNum =
        props.maxLength && props.maxLength > 0 ? props.maxLength : 0;
      const minLengthNum =
        props.minLength && props.minLength > 0 ? props.minLength : 0;
      if (maxLengthNum !== 0 && minLengthNum !== 0) {
        if (text.length > maxLengthNum || text.length < minLengthNum) {
          errors.minMax =
            "This field must be between " +
            minLengthNum +
            " and " +
            maxLengthNum +
            " characters long";
        }
        if (maxLengthNum <= minLengthNum) {
          errors.minMax = "Invalid minimum/maximum length provided";
        }
      }
      if (
        minLengthNum === 0 &&
        maxLengthNum > 0 &&
        text.length > maxLengthNum
      ) {
        errors.minMax =
          "This field must be less than " + maxLengthNum + " characters long";
      }
      if (
        maxLengthNum === 0 &&
        minLengthNum > 0 &&
        text.length < minLengthNum
      ) {
        errors.minMax =
          "This field must be more than " + minLengthNum + " characters long";
      }
    }

    if (props.inputType === "EMAIL") {
      const emailRegex =
        props.emailRegex ??
        /(([^<>()[\\].,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})/;
      const hasValue = text && text.length; // text is sometimes undefined, and sometimes ""
      if (hasValue && !emailRegex.test(text)) {
        errors.email = "Please input a valid email";
      }
      if (
        !hasValue &&
        props.defaultText &&
        props.defaultText.length &&
        !emailRegex.test(props.defaultText)
      ) {
        errors.email = "Please input a valid email as 'Default Value'";
      }
    }

    if (props.inputType === "NUMBER") {
      const hasValue = text && text.length; // text is sometimes undefined, and sometimes ""
      if (hasValue && Number.isNaN(Number(text))) {
        errors.number = "Please input a valid number";
      }
      if (
        !hasValue &&
        props.defaultText &&
        props.defaultText.length &&
        Number.isNaN(Number(props.defaultText))
      ) {
        errors.number = "Please input a valid number as 'Default Value'";
      }
    }

    if (props.inputType === "URL") {
      try {
        text && new URL(text);
      } catch (e) {
        errors.url = "Please input a valid URL";
      }
      if (!text && props.defaultText && props.defaultText.length) {
        try {
          new URL(props.defaultText);
        } catch (e) {
          errors.url = "Please input a valid URL as 'Default Value'";
        }
      }
    }

    return errors;
  },
  //
  getValue: (props, moment, _) => {
    if (props.inputType === "NUMBER") {
      return Number.isNaN(Number(props.text)) || !props.text
        ? null
        : parseFloat(props.text);
    }
    if (props.inputType === "EMAIL") {
      const emailRegex =
        props.emailRegex ??
        /(([^<>()[\\].,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})/;
      if (emailRegex.test(props.text)) {
        return props.text;
      }
      if (
        props.defaultText &&
        props.defaultText.length &&
        emailRegex.test(props.defaultText)
      ) {
        return props.defaultText;
      }
      return null;
    }
    return props.text ?? "";
  },
  //
};

export default derived;
