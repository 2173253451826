import { Classes } from "@blueprintjs/core";
import styled, { css } from "styled-components";
import { DropdownEntireObjectOption } from "legacy/constants/DropdownConstants";
import {
  SingleCellProperties,
  CompactMode,
  TABLE_SIZES,
  TEXT_ALIGN,
} from "../Constants";

export type Position = {
  top: undefined | number;
  left: undefined | number;
  width: undefined | number;
  height: undefined | number;
  maxHeight: undefined | number;
};

/*
  - If options is a string, convert it into an array with a single option
  - For each option in the array:
    - If the option is a string, convert it into an object with the label and value as the same
    - If there is a transformation set, use the transformation to convert the option into an object
    - If label/value keys don't exist, use the first key in the option as the value and the value of that key as the label
*/
export const INVALID_LABEL_MSG = "Invalid label, should be a string";
export const extractDropdownOptions = (
  options: string | Array<string | Record<string, string>>,
  transformation?: { label: string; value: string },
): Array<{ label: string; value: string }> => {
  let opts = options;
  if (!Array.isArray(options)) {
    opts = [options];
  }
  let defaultLabelKey: string | undefined;
  const useEntireObjectAsValue =
    transformation?.value === DropdownEntireObjectOption;

  const optionWithDefaultKey = (option: Record<string, string>) => {
    if (defaultLabelKey) {
      return {
        label:
          typeof option[defaultLabelKey] === "object"
            ? INVALID_LABEL_MSG
            : option[defaultLabelKey],
        value: option,
      };
    } else {
      defaultLabelKey = Object.keys(option).sort()?.[0];
      return {
        label:
          typeof option[defaultLabelKey] === "object"
            ? INVALID_LABEL_MSG
            : option[defaultLabelKey],
        value: option,
      };
    }
  };

  return (opts as Array<string | Record<string, string>>)
    .map((option) => {
      if (!option) {
        return null;
      }
      if (typeof option !== "object") {
        return { label: option, value: option };
      }
      if (transformation) {
        if (
          option[transformation.label] == null ||
          (!useEntireObjectAsValue && option[transformation.value] == null)
        ) {
          return optionWithDefaultKey(option);
        }
        return {
          label:
            typeof option[transformation.label] === "object"
              ? INVALID_LABEL_MSG
              : option[transformation.label],
          value: useEntireObjectAsValue ? option : option[transformation.value],
        };
      }
      if (option.label == null || option.value == null) {
        return optionWithDefaultKey(option);
      }
      return option;
    })
    .filter((opt) => opt && opt.value != null && opt.label != null) as Array<{
    label: string;
    value: string;
  }>;
};

type Props = {
  $position: Position;
  $compactMode: CompactMode;
  cellProperties: SingleCellProperties;
};

// styles for edit cell
const CellStyle = css<Props>`
  border-radius: 4px;
  z-index: 3;
  ${({ $position }) => {
    return css`
      width: ${$position?.width ? $position?.width - 2 : ""}px;
    `;
  }};
  box-shadow:
    0px 0px 1px rgba(27, 30, 34, 0.24),
    0px 1px 3px rgba(27, 30, 34, 0.12),
    0px 12px 24px rgba(27, 30, 34, 0.12);
  .bp5-control-group:not(.bp5-vertical) > *:not(.bp5-divider) {
    margin-right: 0px;
  }
  .bp5-control-group {
    // TODO(wylie): Follow the instructions here for removing .bp5-popover-wrapper
    // https://github.com/palantir/blueprint/wiki/Popover2-migration
    .bp5-input-group::before {
      top: 3px;
      height: 100%;
    }
  }
  &&& {
    .bp5-input,
    .bp5-multi-select {
      border: none !important; // blueprint border style is important, need to override
      .bp5-tag-input-values {
        margin-top: 0px;
      }
    }
  }

  &&&&& {
    input {
      padding-right: 20px;
    }
    .bp5-tag {
      margin-top: 1px;
    }
  }

  position: absolute;
  top: 0px;
  left: 0px;
`;

export const InputCellWrapper = styled.div<Props>`
  ${CellStyle}
  position: absolute;
  top: 0;
  left: 0;
  &&&&& {
    textarea {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    input {
      ${(props) => {
        const height =
          props.$position?.height != null ? props.$position?.height : 0;
        if (height) {
          return `height: ${height}px;`;
        }
      }}
    }
    textarea,
    input {
      padding-left: 10px;
      min-height: ${({ $position, $compactMode }) => {
        const minHeight =
          $position?.height != null
            ? $position?.height - TABLE_SIZES[$compactMode].ROW_PADDING * 2
            : 0;
        return `${minHeight}px`;
      }};
      text-align: ${(props) =>
        props?.cellProperties?.horizontalAlignment &&
        TEXT_ALIGN[props?.cellProperties?.horizontalAlignment]};
      font-size: ${(props) => {
        return `${TABLE_SIZES[props.$compactMode].ROW_FONT_SIZE}px`;
      }};
      line-height: 1.5;
      border: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
`;

export const DropdownCellWrapper = styled.div<Props>`
  ${CellStyle};
  height: 100%;
  .${Classes.CONTROL_GROUP} {
    height: 100%;
  }
  .dropdown-wrapper {
    height: 100%;
    * {
      height: 100%;
    }
    input {
      height: 100%;
    }
  }
`;

export const DateCellWrapper = styled(DropdownCellWrapper)`
  ${CellStyle}
  height: 100%;
  .${Classes.CONTROL_GROUP} {
    height: 100%;
  }
  .${Classes.POPOVER_TARGET} {
    height: 100%;
    * {
      height: 100%;
    }
  }
  &&&&& {
    input {
      height: 100%;
      min-height: 100%;
      top: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
`;
