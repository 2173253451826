import { useCallback, useRef } from "react";

export function useAnimationThrottle<T extends (...args: any[]) => any>(fn: T) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  const throttledFnRef = useRef(false);
  const isCancelled = useRef(false);

  const runFn = useCallback((...args: any[]) => {
    if (isCancelled.current) {
      // If the fn changes
      isCancelled.current = false;
    }
    if (throttledFnRef.current) {
      return;
    }

    throttledFnRef.current = true;
    requestAnimationFrame(() => {
      throttledFnRef.current = false;
      if (!isCancelled.current) {
        fnRef.current?.(...args);
      }
    });

    return () => {
      isCancelled.current = true;
    };
  }, []);

  return runFn;
}
