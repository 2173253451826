import { debounce, DebouncedFunc, DebounceSettings } from "lodash";
import { useEffect, useRef } from "react";

export type { DebounceSettings };

export function useDebounce<T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
  options?: DebounceSettings,
) {
  const debouncedFnRef = useRef<DebouncedFunc<T>>();

  useEffect(() => {
    if (debouncedFnRef.current) {
      debouncedFnRef.current.cancel();
    }

    debouncedFnRef.current = debounce(fn, ms, options);

    return () => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current.cancel();
        debouncedFnRef.current = undefined;
      }
    };
  }, [fn, ms, options]);

  return debouncedFnRef.current;
}
