import { CallOrchestratorPayload, callOrchestrator } from "./call-orchestrator";

// orchestrator has many endpoints that return 4xx's for expected errors, so
// provide this utility so we don't need to duplicate lots of code to capture the error
// message
export async function callWithExpectedError<T>(
  payload: CallOrchestratorPayload,
): Promise<
  | {
      success: true;
      result: T;
      error?: string;
    }
  | {
      success: false;
      result?: void;
      error: string;
    }
> {
  let err: undefined | string;
  const result = await callOrchestrator<T>(payload, {
    notifyOnError: false,
    onError: (httpError) => {
      err = httpError.message;
    },
  });
  if (err) {
    return {
      success: false,
      error: err,
    };
  } else {
    return {
      success: true,
      result: result as T,
    };
  }
}
