import { UserDefinedTheme } from "@superblocksteam/shared";
import { APCAcontrast, sRGBtoY } from "apca-w3";
import tinycolor from "tinycolor2";
import { colors } from "styles/colors";
import {
  CODE_EDITOR_DARK_COLORS,
  CODE_EDITOR_LIGHT_COLORS,
  DARK_APP_BG,
  DARK_MODE_NEUTRALS,
  LIGHT_APP_BG,
  LIGHT_MODE_NEUTRALS,
} from "./constants";

const READABILITY_THRESHOLD = 20;
const CONTRAST_TEXT_THRESHOLD = 25;
const MAX_ITERATIONS = 10;
const PRIMARY_HIGHLIGHT_OPACITY = 0.08;

function getRgbArrayFromHex(hex: string): [number, number, number] {
  // returns an array of numbers representing the rgb values of the hex color
  const color = tinycolor(hex);
  if (color.isValid()) {
    const rgb = color.toRgb();
    return [rgb.r, rgb.g, rgb.b];
  }
  return [0, 0, 0];
}

export function chooseContrastTextColor(primaryColor: string) {
  // Decide contrast text on primary
  const whiteContrast = checkContrast(LIGHT_MODE_NEUTRALS[0], primaryColor);
  const darkContrast = checkContrast(DARK_MODE_NEUTRALS[0], primaryColor);
  const contrastText =
    // prefer white text on primary
    whiteContrast >= darkContrast - CONTRAST_TEXT_THRESHOLD
      ? LIGHT_MODE_NEUTRALS[0]
      : DARK_MODE_NEUTRALS[0];
  return contrastText;
}

function checkContrast(foreground: string, background: string) {
  const textColor = getRgbArrayFromHex(foreground);
  const backgroundColor = getRgbArrayFromHex(background);
  const contrastLc = APCAcontrast(sRGBtoY(textColor), sRGBtoY(backgroundColor));
  return Number(contrastLc) > 0 ? Number(contrastLc) : Number(contrastLc) * -1;
}

function adjustForReadability(foreground: string, background: string) {
  if (checkContrast(foreground, background) > READABILITY_THRESHOLD) {
    return foreground;
  }

  let iterations = 0;
  let fg = foreground;
  while (
    checkContrast(fg, background) < READABILITY_THRESHOLD &&
    iterations < MAX_ITERATIONS
  ) {
    // adjust text color to be lighter or darker
    if (tinycolor(fg).isDark()) {
      fg = tinycolor(fg).lighten(5).toHexString();
    } else {
      fg = tinycolor(fg).darken(5).toHexString();
    }
    iterations++;
  }
  return fg;
}

export const getPrimaryVariants = (
  primaryColor: string,
  neutralColor?: string,
) => {
  // Determine primary variants
  const shouldLighten = tinycolor(primaryColor).isDark();
  // Ensure that the primary value is readable on the background. adjust if needed
  const primary500 = neutralColor
    ? adjustForReadability(primaryColor, neutralColor)
    : primaryColor;
  const primary600 = shouldLighten
    ? tinycolor(primary500).lighten(10).toHexString()
    : tinycolor(primary500).darken(10).toHexString();
  const primary700 = shouldLighten
    ? tinycolor(primary600).lighten(5).toHexString()
    : tinycolor(primary600).darken(5).toHexString();
  return {
    primary500,
    primary600,
    primary700,
  };
};

export function generateThemeColors({
  themePrimaryColor,
  palette,
  isDarkMode,
}: {
  themePrimaryColor: string;
  isDarkMode: boolean;
  palette?: UserDefinedTheme["palette"];
}) {
  const primaryColor =
    palette?.[isDarkMode ? "dark" : "light"]?.primaryColor || themePrimaryColor;
  const appBackground =
    palette?.[isDarkMode ? "dark" : "light"]?.appBackgroundColor;
  // Base neutrals for light and dark modes
  const neutrals = isDarkMode ? DARK_MODE_NEUTRALS : LIGHT_MODE_NEUTRALS;

  // Determine primary variants
  const { primary500, primary600, primary700 } = getPrimaryVariants(
    primaryColor,
    neutrals[0],
  );

  const contrastText = chooseContrastTextColor(primary500);

  return {
    contrastText: contrastText,
    primary500: primary500,
    primary600: primary600,
    primary700: primary700,
    primaryHighlight: tinycolor
      .mix(primary500, neutrals[0], (1 - PRIMARY_HIGHLIGHT_OPACITY) * 100)
      .toHexString(),
    neutral: neutrals[0],
    neutral25: neutrals[1],
    neutral50: neutrals[2],
    neutral100: neutrals[3],
    neutral200: neutrals[4],
    neutral300: neutrals[5],
    neutral400: neutrals[6],
    neutral500: neutrals[7],
    neutral700: neutrals[8],
    neutral900: neutrals[9],
    appBackground: appBackground || (isDarkMode ? DARK_APP_BG : LIGHT_APP_BG),
    editor: isDarkMode ? CODE_EDITOR_DARK_COLORS : CODE_EDITOR_LIGHT_COLORS,
    // constants (not theme-dependent)
    danger: colors.DANGER,
    warning: colors.WARNING,
    info: colors.INFO,
    success: colors.SUCCESS,
    dangerLight: "#fdc5c5",
  };
}
