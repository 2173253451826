import { createAction } from "@reduxjs/toolkit";

type ResetOptions = {
  propertyNames?: string[];
};

export const setMetaProp = createAction(
  "SET_META_PROP",
  (
    widgetId: string,
    propertyName: string,
    propertyValue: any,
    // whether to send to iframe
    skipMessageSending?: boolean,
  ) => ({
    payload: {
      widgetId,
      propertyName,
      propertyValue,
      skipMessageSending,
    },
  }),
);

export const setMetaProps = createAction(
  "SET_META_PROPS",
  (widgetId: string, updates: { [key: string]: any }) => ({
    payload: {
      widgetId,
      updates,
    },
  }),
);

export const resetWidgetMetaProperty = createAction(
  "RESET_WIDGET_META",
  (widgetId: string, resetOptions?: ResetOptions) => ({
    payload: {
      widgetId,
      resetOptions,
    },
  }),
);

export const resetChildrenMetaProperty = createAction(
  "RESET_CHILDREN_WIDGET_META",
  (widgetId: string, spanId?: string) => ({
    payload: {
      widgetId,
      spanId,
    },
  }),
);
