import { Dimension, Padding } from "@superblocksteam/shared";
import {
  CanvasLayout,
  GridDefaults,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { DynamicWidgetsLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import {
  StackDragPositions,
  isStackLayout,
} from "legacy/widgets/StackLayout/utils";
import { FlattenedWidgetLayoutMap } from "legacy/widgets/shared";

// When dragging multiple items from a hstack or vstack onto a fixed grid, we need to know the relative positions of the items that are being dragged
// This is normally fine for fixed grid to fixed grid, because the positions are persisted and correct.
// But in vstacks/hstacks, we don't bother persisting top/left values because they follow the layout rules of the parent container.
// So we need to calculate temporary top/left positions for dragged items so that we can calculate collisions & drop position when hovering over
// fixed grid canvases.
export const getRelativeStackedWidgetPositions = ({
  widgetIds,
  widgets,
  primaryWidgetId,
  dynamicWidgetsLayoutState,
  parentColumnSpace,
}: {
  primaryWidgetId: string; // this is the widget that is the origin for the coordinate calculations
  widgetIds: string[];
  widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap;
  dynamicWidgetsLayoutState: DynamicWidgetsLayoutState;
  parentColumnSpace: number;
}) => {
  const parentId = widgets[widgetIds[0]]?.parentId;
  const parent = parentId && widgets[parentId];
  if (!parent) {
    return;
  }
  const isStack = isStackLayout(parent.layout);
  if (!isStack) {
    return;
  }

  const children = parent.children ?? [];
  const indexMap = children.reduce(
    (acc: Record<string, number>, childId, index) => {
      acc[childId] = index;
      return acc;
    },
    {},
  );
  const sortedWidgetIds = widgetIds.slice().sort((a, b) => {
    return indexMap[a] - indexMap[b];
  });

  const newPositions: StackDragPositions = {};

  const primaryWidgetIndex = sortedWidgetIds.indexOf(primaryWidgetId);
  const beforeList = sortedWidgetIds.slice(0, primaryWidgetIndex);
  const afterList = sortedWidgetIds.slice(primaryWidgetIndex + 1);
  const primaryWidget = widgets[primaryWidgetId];

  // There are some edge cases with dragging new widgets
  // into a stack where the primary widget is not yet
  // in the widgets passed in here. In this case, we just return
  // an empty object of positions which seems to work fine.
  if (!primaryWidget) {
    return newPositions;
  }

  const getOverrideHeight = (
    widget: CanvasWidgetsReduxState[string] | FlattenedWidgetLayoutMap[string],
  ): Dimension<WidgetHeightModes> => {
    if (widget.height.mode === "fillParent") {
      const dynamicHeight = dynamicWidgetsLayoutState[widget.widgetId]?.height;
      if (dynamicHeight) {
        const newHeight = Dimension.toGridUnit(
          dynamicHeight,
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).raw();
        return {
          ...newHeight,
          mode: widget.height.mode,
        };
      }
    }
    return widget.height;
  };

  const getOverrideWidth = (
    widget: CanvasWidgetsReduxState[string] | FlattenedWidgetLayoutMap[string],
  ): Dimension<WidgetWidthModes> => {
    if (widget.width.mode !== "gridUnit" && widget.width.mode !== "px") {
      const dynamicWidth = dynamicWidgetsLayoutState[widget.widgetId]?.width;
      if (dynamicWidth) {
        return dynamicWidth;
      }
    }

    return widget.width;
  };

  const toGridUnit = (
    dimension: Dimension<WidgetWidthModes>,
  ): Dimension<"gridUnit"> => {
    return Dimension.gridUnit(
      Dimension.toGridUnit(dimension, parentColumnSpace).roundUp().value,
    );
  };

  if (parent.layout === CanvasLayout.VSTACK) {
    let nextTopValue = primaryWidget.top.value;
    const pinnedLeft = primaryWidget.left.value;

    beforeList.reverse().forEach((widgetId) => {
      const widget = widgets[widgetId];
      const height = getOverrideHeight(widget);
      nextTopValue -= height.value;
      newPositions[widgetId] = {
        left: Dimension.gridUnit(pinnedLeft),
        top: Dimension.gridUnit(nextTopValue),
        height,
        width: getOverrideWidth(widget),
      };
    });
    nextTopValue =
      primaryWidget.top.value + getOverrideHeight(primaryWidget).value;
    afterList.forEach((widgetId) => {
      const widget = widgets[widgetId];
      const height = getOverrideHeight(widget);
      newPositions[widgetId] = {
        left: Dimension.gridUnit(pinnedLeft),
        top: Dimension.gridUnit(nextTopValue),
        height,
        width: getOverrideWidth(widget),
      };
      nextTopValue += height.value;
    });
    newPositions[primaryWidgetId] = {
      left: Dimension.gridUnit(pinnedLeft),
      top: Dimension.gridUnit(primaryWidget.top.value),
      height: getOverrideHeight(primaryWidget),
      width: getOverrideWidth(primaryWidget),
    };
  } else if (parent.layout === CanvasLayout.HSTACK) {
    let nextLeftValue = primaryWidget.left.value;
    const pinnedTop = primaryWidget.top.value;

    beforeList.reverse().forEach((widgetId) => {
      const widget = widgets[widgetId];
      const width = toGridUnit(getOverrideWidth(widget));

      nextLeftValue -= width.value + 1;
      newPositions[widgetId] = {
        left: Dimension.gridUnit(nextLeftValue),
        top: Dimension.gridUnit(pinnedTop),
        height: getOverrideHeight(widget),
        width,
      };
    });

    nextLeftValue =
      primaryWidget.left.value +
      toGridUnit(getOverrideWidth(primaryWidget)).value +
      1;

    afterList.forEach((widgetId) => {
      const widget = widgets[widgetId];
      const width = toGridUnit(getOverrideWidth(widget));

      newPositions[widgetId] = {
        left: Dimension.gridUnit(nextLeftValue),
        top: Dimension.gridUnit(pinnedTop),
        height: getOverrideHeight(widget),
        width,
      };
      nextLeftValue += width.value + 1;
    });
    newPositions[primaryWidgetId] = {
      left: Dimension.gridUnit(primaryWidget.left.value),
      top: Dimension.gridUnit(pinnedTop),
      height: getOverrideHeight(primaryWidget),
      width: getOverrideWidth(primaryWidget),
    };
  }
  return newPositions;
};

export const getHstackCanvasRemainingWidthPx = (
  canvasWidget: FlattenedWidgetProps,
  widgets: CanvasWidgetsReduxState,
): number => {
  if (!canvasWidget.gridColumns || !canvasWidget.parentColumnSpace) {
    console.error("Parent widget missing gridColumns or parentColumnSpace");
  }

  const gridColumns = canvasWidget.gridColumns || 1;
  const parentColumnSpace = canvasWidget.parentColumnSpace || 1;
  const spacing = canvasWidget.spacing?.value || 0;

  const parentWidthPx =
    gridColumns * parentColumnSpace -
    Padding.x(canvasWidget.padding).value -
    spacing;

  let usedWidthPx = 0;

  const children = canvasWidget.children || [];
  children.forEach((childId) => {
    const child = widgets[childId];
    const childWidthPx = Dimension.toPx(child.width, parentColumnSpace);
    usedWidthPx += childWidthPx.value;
  });

  return parentWidthPx - usedWidthPx;
};
