import SwaggerParser from "@apidevtools/swagger-parser";
import yaml from "js-yaml";
import { call } from "redux-saga/effects";
import { sendErrorUINotification } from "utils/notification";
import { isValidUrl } from "utils/url";
import logger from "../../../../utils/logger";
import { createSaga } from "../../../utils/saga";
import { fetchOpenApiSpecUrl } from "../client";
import slice from "../slice";
import { metadataSelectorKey } from "../utils";

interface FetchOpenApiSpecPayload {
  integrationId: string;
  openApiSpecRef: string;
}

function parseStreamToString(stream: ReadableStream) {
  return new Promise((resolve, reject) => {
    const reader = stream.getReader();
    const decoder = new TextDecoder("utf-8");
    let result = "";

    function readStream() {
      reader
        .read()
        .then(({ done, value }) => {
          if (done) {
            // Make sure to release the resources used by the stream
            reader.releaseLock();
            resolve(result);
            return;
          }

          const chunk = decoder.decode(value, { stream: true });
          result += chunk;

          readStream();
        })
        .catch((error) => {
          reject(error);
        });
    }

    readStream();
  });
}

function* getOpenApiSpecInternal({
  integrationId,
  openApiSpecRef,
}: FetchOpenApiSpecPayload) {
  const response: Response = yield call(fetchOpenApiSpecUrl, openApiSpecRef);
  if (!response.ok) {
    logger.error(`Failed to load OpenAPI spec: ${response.statusText}`);
    sendErrorUINotification({
      message: "Failed to load OpenAPI spec",
    });
    throw new Error(`Failed to load OpenAPI spec: ${response.statusText}`);
  }

  // Is the spec reference a URL or a file name? If it's a URL, assume it's a native integration spec.
  // If it's a file, assume it's a user-uploaded spec.
  const isRemoteSpec = isValidUrl(openApiSpecRef);

  const contentType = response.headers.get("content-type") ?? "";
  const isYaml = contentType.includes("yaml");
  const isJson = contentType.includes("json");

  if (!isYaml && !isJson) {
    throw new Error(
      `Unsupported OpenAPI spec file type. URL: ${openApiSpecRef}. Content type: ${contentType}.`,
    );
  }

  const result = response.body;
  let resultString;
  if (result instanceof ReadableStream) {
    const stringValue: string = yield parseStreamToString(result);
    if (!isRemoteSpec) {
      // This codepath should be active for user-uploaded OpenAPI specs.
      // Bucketeer returns a JSON object with a "file" key that contains the actual spec content.
      const fileObj = JSON.parse(stringValue);
      resultString = fileObj?.file;
    } else {
      resultString = stringValue;
    }
  } else if (typeof result === "string") {
    resultString = result;
  }

  if (resultString) {
    let loader;
    if (openApiSpecRef.endsWith(".yaml") || openApiSpecRef.endsWith(".yml")) {
      loader = yaml.load;
    } else if (openApiSpecRef.endsWith(".json")) {
      loader = JSON.parse;
    }

    // This flag represents whether the spec needs to be parsed or not. In the case that the spec is already an object,
    // we don't need to parse it again.
    const specNeedsParsing = !(typeof resultString === "object");
    const specObject = specNeedsParsing ? loader?.(resultString) : resultString;

    const parser = new SwaggerParser();
    const specResolvedObject: object = yield parser.dereference(specObject, {
      dereference: {
        circular: "ignore", // keep only circular $ref as ref string
      },
    });

    return {
      integrationId,
      openApiSpec: specResolvedObject,
      openApiString: resultString,
    };
  }
  return {};
}

export const getOpenApiSpecSaga = createSaga(
  getOpenApiSpecInternal,
  "getOpenApiSpecSaga",
  {
    sliceName: "datasources",
  },
);

slice.saga(getOpenApiSpecSaga, {
  start(state, { payload }) {
    state.loading[metadataSelectorKey(payload.integrationId)] = true;
    delete state.errors[payload.integrationId];
  },
  success(state, { payload, meta }) {
    const { integrationId, openApiSpec } = payload;
    // not storing the string in redux
    state.meta[meta.args.integrationId] = {
      metadata: {
        integrationId,
        openApiSpec,
      },
    };
    delete state.loading[metadataSelectorKey(meta.args.integrationId)];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.integrationId] = { error: payload };
    delete state.loading[metadataSelectorKey(meta.args.integrationId)];
  },
  store(state, { payload }) {
    const { integrationId, openApiSpec } = payload;
    if (payload?.integrationId)
      state.meta[payload?.integrationId] = {
        metadata: {
          integrationId,
          openApiSpec,
        },
      };
  },
});
