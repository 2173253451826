import { useRef, useCallback } from "react";

// This is used primarily in situations where a function ref is attached to a DOM element but we want to be able
// to update the underlying function ref
export const useCallbackAsRef = <T extends (...args: any[]) => any>(
  callback: T,
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args: any) => callbackRef.current(...args), []);
};
