import { createSelector } from "reselect";

import { ROOT } from "store/utils/types";
import slice from "./slice";

export const selectRepositoriesLoading = createSelector(
  slice.selector,
  (state) => state.loading[ROOT],
);

export const selectRepositories = createSelector(
  slice.selector,
  (state) => state.entities,
);

export const selectRepositoriesAsList = createSelector(
  slice.selector,
  (state) => Object.values(state.entities),
);

export const selectRepoLoading = createSelector(
  slice.selector,
  (state: unknown, id: string) => id,
  (state, id) => state.loading[id],
);
