import { call } from "redux-saga/effects";
import SigningKeyRotationApi, {
  KeyRotationWithCounts,
} from "legacy/api/SigningKeyRotationApi";
import { sendErrorUINotification } from "utils/notification";
import { createSaga } from "../../../utils/saga";
import slice from "../slice";

function* getKeyRotationInternal({ rotationJobId }: { rotationJobId: string }) {
  try {
    const resp: KeyRotationWithCounts = yield call(
      SigningKeyRotationApi.getRotation,
      rotationJobId,
    );
    return resp;
  } catch (e: any) {
    sendErrorUINotification({
      message: e?.message
        ? e.message
        : `Error retrieving resource signing information`,
    });
    return;
  }
}

export const getKeyRotation = createSaga(
  getKeyRotationInternal,
  "getKeyRotation",
  {
    sliceName: "agents",
  },
);

slice.saga(getKeyRotation, {});
