export enum CompactModeTypes {
  SHORT = "SHORT",
  DEFAULT = "DEFAULT",
  TALL = "TALL",
  VERY_SHORT = "VERY_SHORT",
}

export enum PaginationTypes {
  NONE = "NONE",
  CLIENT_SIDE = "CLIENT_SIDE",
  SERVER_SIDE = "SERVER_SIDE",
}

export enum CellAlignmentTypes {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER",
}

export enum VerticalAlignmentTypes {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  CENTER = "CENTER",
}

export enum TextSizes {
  HEADING1 = "HEADING1",
  HEADING2 = "HEADING2",
  HEADING3 = "HEADING3",
  PARAGRAPH = "PARAGRAPH",
  PARAGRAPH2 = "PARAGRAPH2",
}

export enum ImageSize {
  Fixed = "FIXED",
  Fit = "FIT",
  Cover = "COVER",
  Grow = "GROW",
}

export enum EditInputType {
  Text = "TEXT",
  Number = "NUMBER",
  Email = "EMAIL",
  Dropdown = "DROPDOWN",
  Checkbox = "CHECKBOX",
  Date = "DATE",
}

export type CompactMode = keyof typeof CompactModeTypes;
