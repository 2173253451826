import { getTextCssClassFromVariant } from "legacy/themes/utils";

export const getAllTableColumnKeys = (
  tableData: Readonly<Array<Record<string, unknown>>>,
  additionalColumnKeys?: string[],
) => {
  const columnKeys: string[] = [];
  for (let i = 0, tableRowCount = tableData.length; i < tableRowCount; i++) {
    const row = tableData[i];
    for (const key in row) {
      if (!columnKeys.includes(key)) {
        columnKeys.push(key);
      }
    }
  }
  return columnKeys.concat(additionalColumnKeys || []);
};

export const getTableCellTextStyleClassName = ({
  tableCellTextStyleVariant,
  cellTextStyleVariant,
}: {
  tableCellTextStyleVariant?: string;
  cellTextStyleVariant?: string;
}): string | undefined => {
  // Check if we have a column level variant selected
  if (cellTextStyleVariant) {
    return getTextCssClassFromVariant(cellTextStyleVariant);
  }
  // If not, check if we have a table level variant selected
  else if (tableCellTextStyleVariant) {
    return getTextCssClassFromVariant(tableCellTextStyleVariant);
  }

  return undefined;
};
