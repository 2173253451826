import { IntegrationCreate } from "@superblocksteam/schemas";
import { IntegrationDto } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { NEW_ID } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { createDatasource } from "../client";
import slice from "../slice";

function* createDatasourceInternal(datasource: IntegrationCreate) {
  const result: IntegrationDto = yield call(createDatasource, datasource);
  if (!result) throw new Error("No result returned from createDatasource");
  return result;
}

export const createDatasourceSaga = createSaga(
  createDatasourceInternal,
  "createDatasourceSaga",
  { sliceName: "datasources" },
);

slice.saga(createDatasourceSaga, {
  start(state, { payload }) {
    state.loading[payload.id] = true;
    delete state.errors[payload.id];
  },
  success(state, { payload }) {
    state.entities[payload.id] = state.entities[payload.id] ?? {};
    Object.assign(state.entities[payload.id], {
      full: payload,
      kind: payload.kind,
    });
    delete state.loading[NEW_ID];
  },
  error(state, { payload }) {
    state.errors[NEW_ID] = { error: payload };
    delete state.loading[NEW_ID];
  },
});
