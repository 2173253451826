// based on useCopyToClipboard() hook from 'usehooks-ts'
// - adds support for resetting the result after a provided timeout

import { useState } from "react";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useCopyToClipboard(resetDelay?: number): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      if (resetDelay && resetDelay > 0) {
        const timeout = setTimeout(() => {
          setCopiedText(null);
          clearTimeout(timeout);
        }, resetDelay);
      }
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}
