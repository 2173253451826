export class NavigationEvent extends Event {
  destination: {
    sameDocument: boolean;
    url: string;
  };
  formData?: FormData;
  downloadRequest?: boolean;
  newWindow?: boolean;

  constructor(
    isSameDocument: boolean,
    targetUrl: string,
    formData?: FormData,
    isBlob = false,
    newWindow = false,
  ) {
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Event/Event#options
    // cancelable is false by default
    super("navigate", { cancelable: true });
    this.destination = {
      sameDocument: isSameDocument,
      url: targetUrl,
    };
    if (formData) this.formData = formData;
    this.downloadRequest = isBlob;
    this.newWindow = newWindow;
  }
}

// This is a hack to get around the fact that we can't overload the addEventListener method
class NavigationPolyfill extends EventTarget {
  // Parent implements all functionality
  constructor() {
    super();

    interceptWindowClicks(this);

    patchGlobalScope(this);
  }
}

export const navigation =
  (window as any).navigation ?? new NavigationPolyfill();

function interceptWindowClicks(navigation: NavigationPolyfill) {
  function clickCallback(ev: MouseEvent, aEl: HTMLAnchorElement) {
    const isAppNavigation =
      ev.button === 0 &&
      !ev.defaultPrevented &&
      !ev.metaKey &&
      !ev.altKey &&
      !ev.ctrlKey &&
      !ev.shiftKey;

    if (!isAppNavigation) return;
    // If the user writes a relative link such as ?query=true
    const targetUrl = new URL(aEl.href, window.location.origin);
    const isSameDocument =
      targetUrl.origin === window.location.origin &&
      targetUrl.pathname === window.location.pathname;
    const navigationEvent = new NavigationEvent(
      isSameDocument,
      aEl.href,
      undefined,
      targetUrl.protocol === "blob:",
      aEl.target === "_blank",
    );

    // See: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/dispatchEvent#return_value
    const wasCancelled = !navigation.dispatchEvent(navigationEvent);
    if (wasCancelled) {
      ev.preventDefault();
    }
  }

  function submitCallback(ev: SubmitEvent, form: HTMLFormElement) {
    if (ev.defaultPrevented) return;
    const method =
      ev.submitter && "formMethod" in ev.submitter && ev.submitter.formMethod
        ? (ev.submitter.formMethod as string)
        : form.method;
    if (method === "dialog") return;
    const action =
      ev.submitter && "formAction" in ev.submitter && ev.submitter.formAction
        ? (ev.submitter.formAction as string)
        : form.action;
    const navigationEvent = new NavigationEvent(
      false,
      action,
      new FormData(form),
    );
    navigation.dispatchEvent(navigationEvent);
    if (navigationEvent.defaultPrevented) {
      ev.preventDefault();
    }
  }
  window.addEventListener(
    "click",
    (ev: MouseEvent) => {
      const aEl = (ev.target as Element).closest?.("a[href]");
      if (aEl instanceof HTMLAnchorElement) {
        clickCallback(ev, aEl);
      }
    },
    true,
  );
  window.addEventListener("submit", (ev: SubmitEvent) => {
    const form: unknown = (ev.target as Element).closest?.("form");
    if (form instanceof HTMLFormElement) {
      submitCallback(ev, form);
    }
  });
}

function patchGlobalScope(navigation: NavigationPolyfill) {
  try {
    Object.defineProperty(window, "navigation", {
      value: navigation,
    });
  } catch {
    //
  }
}
