import { select } from "redux-saga/effects";
import { callSagas, createSaga } from "store/utils/saga";
import { selectAllV2Apis, selectV2ApiMeta } from "../selectors";
import slice, { type ApiDtoWithPb, type ApiV2Meta } from "../slice";
import { persistV2ApiSaga } from "./persistV2Api";

function* saveFailedV2ApisInternal() {
  const apis: Record<string, ApiDtoWithPb> = yield select(selectAllV2Apis);
  const apiMeta: Record<string, ApiV2Meta> = yield select(selectV2ApiMeta);

  const apisToReSave: ApiDtoWithPb[] = [];

  Object.entries(apis).forEach(([id, api]) => {
    if (apiMeta[id]?.dirty) {
      apisToReSave.push(api);
    }
  });
  try {
    yield callSagas(
      apisToReSave.map((api) => persistV2ApiSaga.apply({ api: api.apiPb })),
    );
  } catch (e) {
    //error handling is done by persistApi call
  }
}

export const saveFailedV2ApisSaga = createSaga(
  saveFailedV2ApisInternal,
  "saveFailedV2ApisSaga",
  {
    sliceName: "apis",
  },
);

slice.saga(saveFailedV2ApisSaga, {});
