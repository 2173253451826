import { call } from "redux-saga/effects";
import SigningKeyRotationApi, {
  RotationResponse,
} from "legacy/api/SigningKeyRotationApi";
import { createSaga } from "../../../utils/saga";
import slice from "../slice";

function* getKeyRotationsInternal({
  onlyInProgress,
}: {
  onlyInProgress?: boolean;
}) {
  const rotationResponse: RotationResponse = yield call(
    SigningKeyRotationApi.getRotations,
    onlyInProgress ?? false,
  );
  return rotationResponse;
}

export const getKeyRotations = createSaga(
  getKeyRotationsInternal,
  "getKeyRotations",
  {
    sliceName: "agents",
  },
);

slice.saga(getKeyRotations, {
  start(state, { payload }) {
    delete state.errors.keyRotations;
  },
  success(state, { payload }) {
    delete state.errors.keyRotations;
  },
  error(state, { payload }) {
    state.errors.keyRotations = { error: payload };
  },
});
