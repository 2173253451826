import { select } from "redux-saga/effects";
import { callSagas, createSaga } from "store/utils/saga";
import { selectAllApis, selectApiMeta } from "../selectors";
import slice, { ApiMeta } from "../slice";
import { ApiDto } from "../types";
import { persistApiSaga } from "./persistApi";

function* saveFailedApisInternal() {
  const apis: Record<string, ApiDto> = yield select(selectAllApis);
  const apiMeta: Record<string, ApiMeta> = yield select(selectApiMeta);

  const apisToReSave: ApiDto[] = [];

  Object.entries(apis).forEach(([id, api]) => {
    if (apiMeta[id]?.dirty) {
      apisToReSave.push(api);
    }
  });
  try {
    yield callSagas(
      apisToReSave.map((api) =>
        persistApiSaga.apply({ api, shouldUpdateState: true }),
      ),
    );
  } catch (e) {
    //error handling is done by persistApi call
  }
}

export const saveFailedApisSaga = createSaga(
  saveFailedApisInternal,
  "saveFailedApisSaga",
  {
    sliceName: "apis",
  },
);

slice.saga(saveFailedApisSaga, {});
