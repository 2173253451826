import { ViewMode } from "@superblocksteam/shared";

// TODO: Investigate https://app.clickup.com/t/24hr4fe
export enum APP_MODE {
  EDIT = "EDIT",
  PUBLISHED = "PUBLISHED",
  PREVIEW = "PREVIEW",
}

export const ModeMap = {
  [APP_MODE.EDIT]: ViewMode.EDITOR,
  [APP_MODE.PUBLISHED]: ViewMode.DEPLOYED,
  [APP_MODE.PREVIEW]: ViewMode.PREVIEW,
};
