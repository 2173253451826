import moment from "moment-timezone";
import { CurrencyList } from "legacy/constants/FormatConstants";
import { ISO_DATE_FORMAT } from "legacy/constants/WidgetValidation";

export const backwardsCompatibleMomentFormatter = (
  format?: string,
  fallback?: string,
): string => {
  if (format === "Epoch") {
    return "X";
  }
  switch (format) {
    case "Epoch":
      return "X";
    case "Milliseconds":
      return "x";
    case null:
    case undefined:
    case "":
      return fallback || ISO_DATE_FORMAT;
    default:
      return format;
  }
};

const valueToNumber = (value?: string | number): number | undefined => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    // Remove all dollar signs or commmas so we can properly parse the value
    // into a number
    const searchRegex = new RegExp("[^e0-9.+-]", "gi");
    return parseFloat(value.replaceAll(searchRegex, "") ?? "");
  }
};

type NumberFormatOption = {
  label: string;
  value: Intl.NumberFormatOptions["notation"] | "unformatted";
};

export const NUMBER_FORMATTING_OPTIONS: NumberFormatOption[] = [
  {
    label: "Unformatted",
    value: "unformatted",
  },
  {
    label: "Standard - 10,000",
    value: "standard",
  },
  {
    label: "Compact - 10K",
    value: "compact",
  },
  {
    label: "Scientific - 1E4",
    value: "scientific",
  },
  {
    label: "Engineering - 10E3",
    value: "engineering",
  },
];

export const DATE_INPUT_FORMATS = [
  {
    label: "Auto",
    value: undefined,
  },
  {
    label: "UNIX timestamp (s)",
    value: "X",
  },
  {
    label: "UNIX timestamp (ms)",
    value: "x",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY",
  },
  {
    label: "MM-DD-YYYY HH:mm",
    value: "MM-DD-YYYY HH:mm",
  },
  {
    label: "MM-DD-YYYY HH:mm:ss",
    value: "MM-DD-YYYY HH:mm:ss",
  },
  {
    label: "MM-DD-YYYY hh:mm:ss a",
    value: "MM-DD-YYYY hh:mm:ss a",
  },
  {
    label: "MM-DD-YYYYTHH:mm:ss.sssZ",
    value: "MM-DD-YYYYTHH:mm:ss.sssZ",
  },
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DD HH:mm",
    value: "YYYY-MM-DD HH:mm",
  },
  {
    label: "YYYY-MM-DD HH:mm:ss",
    value: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "YYYY-MM-DD HH:mm:ssZ",
    value: "YYYY-MM-DD HH:mm:ssZ",
  },
  {
    label: "YYYY-MM-DDTHH:mm:ss.sssZ",
    value: "YYYY-MM-DDTHH:mm:ss.sssZ",
  },
  {
    label: "YYYY-MM-DD hh:mm:ss a",
    value: "YYYY-MM-DD hh:mm:ss a",
  },
  {
    label: "YYYY-MM-DDTHH:mm:ss",
    value: "YYYY-MM-DDTHH:mm:ss",
  },
  {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY",
  },
  {
    label: "DD-MM-YYYY HH:mm",
    value: "DD-MM-YYYY HH:mm",
  },
  {
    label: "DD-MM-YYYY HH:mm:ss",
    value: "DD-MM-YYYY HH:mm:ss",
  },
  {
    label: "DD-MM-YYYY hh:mm:ss a",
    value: "DD-MM-YYYY hh:mm:ss a",
  },
  {
    label: "DD-MM-YYYYTHH:mm:ss.sssZ",
    value: "DD-MM-YYYYTHH:mm:ss.sssZ",
  },
  {
    label: "Do MMM YYYY",
    value: "Do MMM YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
  {
    label: "MM/DD/YYYY HH:mm",
    value: "MM/DD/YYYY HH:mm",
  },
  {
    label: "MM/DD/YYYY HH:mm:ss",
    value: "MM/DD/YYYY HH:mm:ss",
  },
  {
    label: "MM/DD/YYYY hh:mm:ss a",
    value: "MM/DD/YYYY hh:mm:ss a",
  },
  {
    label: "MM/DD/YYYYTHH:mm:ss.sssZ",
    value: "MM/DD/YYYYTHH:mm:ss.sssZ",
  },
  {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
  },
  {
    label: "YYYY/MM/DD HH:mm",
    value: "YYYY/MM/DD HH:mm",
  },
  {
    label: "YYYY/MM/DD HH:mm:ss",
    value: "YYYY/MM/DD HH:mm:ss",
  },
  {
    label: "YYYY/MM/DD hh:mm:ss a",
    value: "YYYY/MM/DD hh:mm:ss a",
  },
  {
    label: "YYYY/MM/DDTHH:mm:ss",
    value: "YYYY/MM/DDTHH:mm:ss",
  },
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
  },
  {
    label: "DD/MM/YYYY HH:mm",
    value: "DD/MM/YYYY HH:mm",
  },
  {
    label: "DD/MM/YYYY HH:mm:ss",
    value: "DD/MM/YYYY HH:mm:ss",
  },
  {
    label: "DD/MM/YYYY hh:mm:ss a",
    value: "DD/MM/YYYY hh:mm:ss a",
  },
  {
    label: "DD/MM/YYYYTHH:mm:ss.sssZ",
    value: "DD/MM/YYYYTHH:mm:ss.sssZ",
  },
];

export const DATE_OUTPUT_FORMATS: Array<{
  label: string;
  value: string | undefined;
  // contains the date part of the format contained in value
  valueDatePart: string | undefined;
}> = [
  {
    label: "UNIX timestamp (s)",
    value: "X",
    // use ISO-8601 dates as the truncated version of unix timestamps
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "UNIX timestamp (ms)",
    value: "x",
    // use ISO-8601 dates as the truncated version of unix timestamps
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY",
    valueDatePart: "MM-DD-YYYY",
  },
  {
    label: "MM-DD-YYYY HH:mm",
    value: "MM-DD-YYYY HH:mm",
    valueDatePart: "MM-DD-YYYY",
  },
  {
    label: "MM-DD-YYYY HH:mm:ss",
    value: "MM-DD-YYYY HH:mm:ss",
    valueDatePart: "MM-DD-YYYY",
  },
  {
    label: "MM-DD-YYYY hh:mm:ss a",
    value: "MM-DD-YYYY hh:mm:ss a",
    valueDatePart: "MM-DD-YYYY",
  },
  {
    label: "MM-DD-YYYYTHH:mm:ss.sssZ",
    value: "MM-DD-YYYYTHH:mm:ss.sssZ",
    valueDatePart: "MM-DD-YYYY",
  },
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DD HH:mm",
    value: "YYYY-MM-DD HH:mm",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DD HH:mm:ss",
    value: "YYYY-MM-DD HH:mm:ss",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DD HH:mm:ssZ",
    value: "YYYY-MM-DD HH:mm:ssZ",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DDTHH:mm:ss.sssZ",
    value: "YYYY-MM-DDTHH:mm:ss.sssZ",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DD hh:mm:ss a",
    value: "YYYY-MM-DD hh:mm:ss a",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "YYYY-MM-DDTHH:mm:ss",
    value: "YYYY-MM-DDTHH:mm:ss",
    valueDatePart: "YYYY-MM-DD",
  },
  {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY",
    valueDatePart: "DD-MM-YYYY",
  },
  {
    label: "DD-MM-YYYY HH:mm",
    value: "DD-MM-YYYY HH:mm",
    valueDatePart: "DD-MM-YYYY",
  },
  {
    label: "DD-MM-YYYY HH:mm:ss",
    value: "DD-MM-YYYY HH:mm:ss",
    valueDatePart: "DD-MM-YYYY",
  },
  {
    label: "DD-MM-YYYY hh:mm:ss a",
    value: "DD-MM-YYYY hh:mm:ss a",
    valueDatePart: "DD-MM-YYYY",
  },
  {
    label: "DD-MM-YYYYTHH:mm:ss.sssZ",
    value: "DD-MM-YYYYTHH:mm:ss.sssZ",
    valueDatePart: "DD-MM-YYYY",
  },
  {
    label: "Do MMM YYYY",
    value: "Do MMM YYYY",
    valueDatePart: "Do MMM YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
    valueDatePart: "MM/DD/YYYY",
  },
  {
    label: "MM/DD/YYYY HH:mm",
    value: "MM/DD/YYYY HH:mm",
    valueDatePart: "MM/DD/YYYY",
  },
  {
    label: "MM/DD/YYYY HH:mm:ss",
    value: "MM/DD/YYYY HH:mm:ss",
    valueDatePart: "MM/DD/YYYY",
  },
  {
    label: "MM/DD/YYYY hh:mm:ss a",
    value: "MM/DD/YYYY hh:mm:ss a",
    valueDatePart: "MM/DD/YYYY",
  },
  {
    label: "MM/DD/YYYYTHH:mm:ss.sssZ",
    value: "MM/DD/YYYYTHH:mm:ss.sssZ",
    valueDatePart: "MM/DD/YYYY",
  },
  {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
    valueDatePart: "YYYY/MM/DD",
  },
  {
    label: "YYYY/MM/DD HH:mm",
    value: "YYYY/MM/DD HH:mm",
    valueDatePart: "YYYY/MM/DD",
  },
  {
    label: "YYYY/MM/DD HH:mm:ss",
    value: "YYYY/MM/DD HH:mm:ss",
    valueDatePart: "YYYY/MM/DD",
  },
  {
    label: "YYYY/MM/DD hh:mm:ss a",
    value: "YYYY/MM/DD hh:mm:ss a",
    valueDatePart: "YYYY/MM/DD",
  },
  {
    label: "YYYY/MM/DDTHH:mm:ss",
    value: "YYYY/MM/DDTHH:mm:ss",
    valueDatePart: "YYYY/MM/DD",
  },
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
    valueDatePart: "DD/MM/YYYY",
  },
  {
    label: "DD/MM/YYYY HH:mm",
    value: "DD/MM/YYYY HH:mm",
    valueDatePart: "DD/MM/YYYY",
  },
  {
    label: "DD/MM/YYYY HH:mm:ss",
    value: "DD/MM/YYYY HH:mm:ss",
    valueDatePart: "DD/MM/YYYY",
  },
  {
    label: "DD/MM/YYYY hh:mm:ss a",
    value: "DD/MM/YYYY hh:mm:ss a",
    valueDatePart: "DD/MM/YYYY",
  },
  {
    label: "DD/MM/YYYYTHH:mm:ss.sssZ",
    value: "DD/MM/YYYYTHH:mm:ss.sssZ",
    valueDatePart: "DD/MM/YYYY",
  },
];

export const TIMEZONE_OPTIONS: Array<{
  label: string;
  value: undefined | string;
}> = [
  { label: "Local", value: undefined },
  // sort UTC first - we show both etc/utc and utc because we switched from utc to etc/utc
  // and now need to include both values for backwards compatibility
  { label: "Etc/UTC", value: "Etc/UTC" },
  { label: "UTC", value: "UTC" },
  ...moment.tz
    .names()
    .filter((tz) => tz !== "UTC" && tz !== "Etc/UTC")
    .map((tz) => ({
      label: tz,
      value: tz,
    })),
];

// a lookup table for extracting the date part of an output format
const datePartOfOutputFormatMap = new Map(
  DATE_OUTPUT_FORMATS.map(({ value, valueDatePart }) => [value, valueDatePart]),
);

export function datePartOfOutputFormat(
  outputFormat: string | undefined,
): string | undefined {
  if (outputFormat === undefined) return undefined;
  return datePartOfOutputFormatMap.get(outputFormat) ?? outputFormat;
}

export function parseDateStr(
  value: string,
  inputFormat: string | undefined,
  ignoreInvalid = false,
): moment.Moment | undefined {
  if (!value) return undefined;
  let m: moment.Moment;
  try {
    m = moment(value, backwardsCompatibleMomentFormatter(inputFormat));
  } catch {
    m = moment.invalid();
  }
  if (ignoreInvalid && !m.isValid()) return undefined;
  return m;
}

export function formatMoment(
  date: moment.Moment | undefined,
  outputFormat?: string,
  inputFormat?: string,
): string {
  if (!date) return "";
  if (!date.isValid()) return "Invalid Value";
  return date.format(backwardsCompatibleMomentFormatter(outputFormat));
}

export const formatIncludesTimezone = (dateFormat: string) => {
  return (
    (typeof dateFormat === "string" &&
      dateFormat.toLowerCase().indexOf("z") !== -1) ||
    dateFormat.toLowerCase() === "x"
  );
};

export const formatDate = (
  value: unknown,
  inputFormat?: string,
  outputFormat?: string,
  inputTimezone?: string,
  outputTimezone?: string,
) => {
  if (typeof value !== "string" && typeof value !== "number") {
    return "Invalid date";
  }
  if (value === "") return "";

  // inputFormat is often an empty string, so || is intentional
  const dateFormat = backwardsCompatibleMomentFormatter(
    inputFormat,
    typeof value === "number" ? "x" : ISO_DATE_FORMAT,
  );
  // fallback behavior converts unix timestamps to ISO8601
  const displayFormat = backwardsCompatibleMomentFormatter(
    outputFormat,
    ISO_DATE_FORMAT,
  );

  if (outputTimezone) {
    const date =
      typeof value === "number"
        ? moment.tz(value.toString(), "x", outputTimezone)
        : moment.tz(value, dateFormat, outputTimezone);
    if (displayFormat.includes("Z") && date.utcOffset() === 0) {
      return date.format(displayFormat.replaceAll("Z", "[Z]"));
    }
    return date.format(displayFormat);
  }
  if (inputTimezone) {
    const date =
      typeof value === "number"
        ? moment.tz(value.toString(), "x", inputTimezone)
        : moment.tz(value, dateFormat, inputTimezone);
    if (displayFormat.includes("Z") && date.utcOffset() === 0) {
      return date.format(displayFormat.replaceAll("Z", "[Z]"));
    }
    return date.format(displayFormat);
  }
  try {
    let date = moment(value, dateFormat);
    if (!date.isValid()) {
      // if using the fallback format failed, try not using any format
      date = moment(value);
    }
    return date.format(displayFormat);
  } catch (e) {
    if (value) {
      return "Invalid Value";
    } else {
      return "";
    }
  }
};

const getFractionDigitsNumber = (
  fractionDigits: number | string | undefined,
) => {
  const digitsNumber = valueToNumber(fractionDigits);
  if (digitsNumber === undefined) return undefined;

  return !isNaN(digitsNumber) && 0 <= digitsNumber && digitsNumber <= 20
    ? digitsNumber
    : undefined;
};

export const formatNumber = (
  value?: string | number | null,
  notation?: Intl.NumberFormatOptions["notation"] | "unformatted",
  minimumFractionDigits?: number | string,
  maximumFractionDigits?: number | string,
): string => {
  if (value === null || value === "null" || value === "") return "";

  const number = valueToNumber(value);

  if (number === undefined || isNaN(number)) {
    return "Invalid number";
  }

  minimumFractionDigits = getFractionDigitsNumber(minimumFractionDigits);
  maximumFractionDigits = getFractionDigitsNumber(maximumFractionDigits);
  if (
    minimumFractionDigits !== undefined &&
    maximumFractionDigits !== undefined &&
    minimumFractionDigits > maximumFractionDigits
  ) {
    maximumFractionDigits = undefined;
  }

  if (!notation || notation === "unformatted") {
    return Intl.NumberFormat("en-US", {
      notation: "standard",
      minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits ?? 20,
    })
      .format(number)
      .replaceAll(",", "");
  }

  return Intl.NumberFormat("en-US", {
    notation,
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits ?? 20,
  }).format(number);
};

export const formatPercentage = (
  value?: string | number | null,
  minimumFractionDigits?: number | string,
  maximumFractionDigits?: number | string,
): string => {
  if (value === null || value === "null" || value === "") return "";

  const number = valueToNumber(value);

  if (number === undefined || isNaN(number)) {
    return "Invalid percentage";
  }

  minimumFractionDigits = getFractionDigitsNumber(minimumFractionDigits);
  maximumFractionDigits = getFractionDigitsNumber(maximumFractionDigits);
  if (
    minimumFractionDigits !== undefined &&
    maximumFractionDigits !== undefined &&
    minimumFractionDigits > maximumFractionDigits
  ) {
    maximumFractionDigits = undefined;
  }

  return new Intl.NumberFormat("en-US", {
    notation: "standard",
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits ?? 20,
    style: "percent",
  }).format(number);
};

export const formatCurrency = (
  value?: string | number | null,
  currency?: string,
  notation?: Intl.NumberFormatOptions["notation"] | "unformatted",
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
): string => {
  if (value === null || value === "null" || value === "") return "";

  const number = valueToNumber(value);

  if (number === undefined || isNaN(number)) {
    return "Invalid amount";
  }
  minimumFractionDigits = getFractionDigitsNumber(minimumFractionDigits);
  maximumFractionDigits = getFractionDigitsNumber(maximumFractionDigits);
  if (
    minimumFractionDigits !== undefined &&
    maximumFractionDigits !== undefined &&
    minimumFractionDigits > maximumFractionDigits
  ) {
    maximumFractionDigits = undefined;
  }
  if (currency !== undefined && !CurrencyList.includes(currency)) {
    currency = "USD";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits,
    maximumFractionDigits,
    notation: notation !== "unformatted" ? notation : "standard",
  }).format(number);
};
