export enum OrchestratorApiPaths {
  HEALTHCHECK = "health",
  API_EXECUTE = "v2/execute",
  DATASOURCE_TEST = "v2/test",
  DATASOURCE_METADATA = "v2/metadata",
  DATASOURCE_DELETE = "v2/delete",

  CHECK_AUTH = "v1/check-auth",
  AUTH_LOGIN = "v1/login",
  AUTH_LOGOUT = "v1/logout",
  EXCHANGE_CODE = "v1/exchange-code",
  REQUEST_TOKEN = "v1/request-token",
  CLEAR_CACHE = "v1/secrets/stores/:datasourceSlug/configurations/:configurationId/invalidate",
  SECRET_METADATA = "v1/secrets/stores/:datasourceSlug",
  // Resource signing
  SIGN_RESOURCE = "v1/signature/sign",
  VERIFY_RESOURCE = "v1/signature/verify",
}
