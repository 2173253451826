import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled, { keyframes } from "styled-components";
import { SUPERBLOCKS_UI_MATERIAL_ICON_CDN_PATH } from "../../env";

const shineAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

const Skeleton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(110deg, #ececec 10%, #f5f5f5 30%, #ececec 60%);
  background-size: 200% 100%;
  animation: 0.5s ${shineAnimation} linear infinite;
`;

const StyledIconWrapper = styled.div<{
  fill: boolean;
  size?: number;
  sizeWithUnits?: string;
}>`
  &,
  & > div,
  svg {
    height: ${(props) =>
      props.fill ? "100%" : props.sizeWithUnits || `${props.size}px`};
    width: ${(props) =>
      props.fill ? "100%" : props.sizeWithUnits || `${props.size}px`};
    fill: currentColor;
    display: flex;
  }
`;

const DynamicSVG = ({
  iconName,
  fill = false,
  loadingAnimation = false,
  size = 20,
  sizeWithUnits,
  disablePointerEvents,
}: {
  iconName?: string;
  size?: number;
  sizeWithUnits?: string;
  fill?: boolean;
  loadingAnimation?: boolean;
  disablePointerEvents?: boolean;
}) => {
  const normalizedIconName = useMemo(() => {
    try {
      if (!iconName) return null;
      if (iconName?.trim().startsWith("<Icon")) {
        // Match the first word after name=" or name='
        const match = iconName.match(/(?<=name=["'])(\w+)/);
        return match ? match[0] : iconName;
      }
      return iconName;
    } catch (e) {
      return iconName;
    }
  }, [iconName]);
  const lowerCaseIconName = useMemo(() => {
    try {
      return normalizedIconName?.toLowerCase();
    } catch (e) {
      return normalizedIconName;
    }
  }, [normalizedIconName]);
  if (loadingAnimation || !lowerCaseIconName) {
    return (
      <StyledIconWrapper size={size} sizeWithUnits={sizeWithUnits} fill={fill}>
        <Skeleton />
      </StyledIconWrapper>
    );
  }
  return (
    <StyledIconWrapper
      className="DyanmicSVGIconWrapper"
      size={size}
      sizeWithUnits={sizeWithUnits}
      fill={fill}
      style={
        disablePointerEvents
          ? {
              pointerEvents: "none",
            }
          : undefined
      }
    >
      <ReactSVG
        key={lowerCaseIconName}
        src={`${SUPERBLOCKS_UI_MATERIAL_ICON_CDN_PATH}/${lowerCaseIconName}.svg`}
        loading={() => <Skeleton />}
        onError={(error) =>
          console.error(`Failed to load ${iconName}: ${error}`)
        }
      />
    </StyledIconWrapper>
  );
};

export default DynamicSVG;
