import { Spinner } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { TextAlign } from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { chooseContrastTextColor } from "legacy/themes/generateColors";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { styleAsClass } from "styles/styleAsClass";
import { generateBorderStyleObject } from "../../base/generateBorderStyle";
import { generatePaddingStyleObject } from "../../base/generatePaddingStyle";
import {
  getButtonThemeDefaultBackgroundColor,
  getButtonBorderStyleObjectV1,
  getFallbackBorderColor,
  getButtonThemeDefaultHoverBackgroundColor,
  getButtonThemeDefaultHoverTextColor,
  getButtonThemeDefaultTextColor,
} from "./buttonSpecificStyles";
import {
  generateHoverVariableAssignmentStyleObject,
  getHoverCss,
} from "./sharedHoverStyles";
import {
  ButtonLikeLinkStyle,
  ButtonStyle,
  ButtonProps,
  ButtonLikeLinkProps,
  SharedButtonProps,
} from "./types";

const SharedButtonClass = styleAsClass`
  width: 100%;
  height: 100%;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  text-align: center;
  overflow: hidden;

  &.text-align-left {
    justify-content: flex-start;

    .button-content {
      margin: 0;
    }
  }

  &.text-align-right {
    justify-content: flex-end;

    .button-content {
      margin: 0;
    }
  }

  .button-text {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    max-height: 100%;
  }
  .button-content {
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

const ButtonClass = styleAsClass`
  &.${CLASS_NAMES.DISABLED_MODIFIER} {
    border-color: transparent !important;
  }
`;

const ButtonLikeLinkClass = styleAsClass`
  cursor: default;

  .button-content {
    justify-content: center;
  }

  &.text-align-left {
    text-align: left;

    .button-content {
      justify-content: flex-start;
    }
  }

  &.text-align-right {
    text-align: right;

    .button-content {
      justify-content: flex-end;
    }
  }

  a {
    transition: background-color 0.2s;

    &.${CLASS_NAMES.DISABLED_MODIFIER} {
      .button-text.${CLASS_NAMES.DISABLED_MODIFIER} {
        color: inherit;
      }

      border-color: transparent !important;
    }

    &.${CLASS_NAMES.LINK} {
      &[data-content-width="100%"] {
        .button-text {
          white-space: wrap;
        }
      }
    }
  }
`;

const ButtonLoader = styleAsClass`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  > div {
    height: 100%;
  }
`;

const ButtonHoverClass = styleAsClass`${getHoverCss(":disabled")}`;

const ButtonLikeLinkHoverClass = styleAsClass`${getHoverCss(
  `.${CLASS_NAMES.DISABLED_MODIFIER}`,
)}`;

const getClassNameForStyle = (style?: ButtonStyle | ButtonLikeLinkStyle) => {
  switch (style) {
    case "PRIMARY_BUTTON":
      return CLASS_NAMES.PRIMARY_BUTTON;
    case "SECONDARY_BUTTON":
      return CLASS_NAMES.SECONDARY_BUTTON;
    case "TERTIARY_BUTTON":
      return CLASS_NAMES.TERTIARY_BUTTON;
    case "LINK":
      return CLASS_NAMES.LINK;
    default:
      return CLASS_NAMES.SECONDARY_BUTTON;
  }
};

const getTextAlignmentClass = (textAlignment?: TextAlign) => {
  switch (textAlignment) {
    case TextAlign.LEFT:
      return "text-align-left";
    case TextAlign.RIGHT:
      return "text-align-right";
    default:
      return "text-align-center";
  }
};

export const FIT_CONTENT_PADDING = 9;

const useSharedStyles = ({
  backgroundColor,
  style,
  width,
  height,
  padding,
  ...props
}: SharedButtonProps) => {
  return useMemo(() => {
    const paddingStyleObject = padding
      ? generatePaddingStyleObject(padding)
      : {};

    return {
      ...style,
      width: width ?? "100%",
      height: height ?? "100%",
      paddingTop: height === "auto" ? `${FIT_CONTENT_PADDING}px` : undefined, // 9px allows FC height to match default fixed height
      paddingBottom: height === "auto" ? `${FIT_CONTENT_PADDING}px` : undefined,
      maxWidth: props.maxWidth ? `${props.maxWidth}px` : style?.maxWidth,
      minWidth: props.minWidth ? `${props.minWidth}px` : style?.minWidth,
      background: props.disabled ? undefined : backgroundColor,
      cursor: props.disabled
        ? "not-allowed"
        : props.isLoading
          ? "default"
          : "pointer",
      ...paddingStyleObject,
    };
  }, [
    backgroundColor,
    style,
    width,
    height,
    props.maxWidth,
    props.minWidth,
    props.disabled,
    props.isLoading,
    padding,
  ]);
};

const useButtonContent = (
  props: SharedButtonProps,
  forceTextColorWhenDisabled?: boolean,
) => {
  const {
    textProps,
    textColor,
    backgroundColor,
    useDynamicContrast,
    icon,
    text,
    iconPosition,
    disabled,
  } = props;
  const textClassName = useMemo(() => {
    const typographyClassName =
      textProps?.textStyleVariant !== undefined
        ? getTextCssClassFromVariant(textProps.textStyleVariant)
        : "";

    return `button-text ${typographyClassName}`;
  }, [textProps?.textStyleVariant]);

  const styleForText = useMemo(() => {
    // We overwrite the typography's text color, in favor of the textColor property or its dynamic color
    const style: React.CSSProperties = {
      ...textProps?.style,
      color: textColor,
    };

    if (
      backgroundColor &&
      typeof backgroundColor === "string" &&
      useDynamicContrast &&
      !textColor
    ) {
      style.color = chooseContrastTextColor(backgroundColor);
    }

    if (disabled && !forceTextColorWhenDisabled) {
      style.color = "";
    }

    return style;
  }, [
    textProps?.style,
    textColor,
    backgroundColor,
    useDynamicContrast,
    disabled,
    forceTextColorWhenDisabled,
  ]);

  return useMemo(() => {
    if (!icon) {
      return (
        <div className="button-content">
          <span
            className={`${textClassName} ${
              disabled ? CLASS_NAMES.DISABLED_MODIFIER : ""
            }`}
            style={styleForText}
          >
            {text}
          </span>
        </div>
      );
    }
    // force color for icon
    return (
      <div
        className="button-content"
        style={
          !disabled || forceTextColorWhenDisabled ? { color: textColor } : {}
        }
      >
        {iconPosition === "LEFT" && icon}
        {text && (
          <span
            className={`${textClassName} ${
              disabled ? CLASS_NAMES.DISABLED_MODIFIER : ""
            }`}
            style={styleForText}
          >
            {text}
          </span>
        )}
        {iconPosition === "RIGHT" && icon}
      </div>
    );
  }, [
    icon,
    disabled,
    forceTextColorWhenDisabled,
    textColor,
    iconPosition,
    text,
    textClassName,
    styleForText,
  ]);
};

export const Button = (props: ButtonProps) => {
  const {
    buttonStyle,
    border,
    borderRadius,
    backgroundColor: backgroundColorIfSet,
    textColor: textColorIfSet,
    textAlignment,
    isLoading,
    disabled,
  } = props;

  const version = props.version ?? "v1";

  const classNames = [
    SharedButtonClass,
    ButtonClass,
    ButtonHoverClass,
    getClassNameForStyle(buttonStyle),
    isLoading ? "loading-button" : "",
    disabled ? CLASS_NAMES.DISABLED_MODIFIER : "",
    getTextAlignmentClass(textAlignment),
  ]
    .filter(Boolean)
    .join(" ");

  const theme = useSelector(selectGeneratedTheme);

  const backgroundColor =
    backgroundColorIfSet ??
    getButtonThemeDefaultBackgroundColor(theme, buttonStyle);

  const sharedStyles = useSharedStyles(props);
  const buttonSpecificStyles = useMemo(() => {
    const borderStyleObject = generateBorderStyleObject({
      border,
      borderRadius,
      fallbackBorderColor: getFallbackBorderColor(
        buttonStyle,
        backgroundColor,
        theme.colors.primary500,
      ),
    });

    if (version === "v1") {
      /**
       * - v1 is the original version for used in the ButtonWidget, and TableWidget, which is legacy & limited. E.g:
       *   - background color is only available for PRIMARY_STYLE
       *   - border color is only available for SECONDARY_STYLE
       *   - border width is only available for PRIMARY_STYLE
       *
       * - v2 is the new version for used in the MenuWidget, and follows a similar pattern as Link Widget.
       *   - Primary, secondary & tertiary styles support all properties
       */
      const backgroundStyleObject = {
        background:
          buttonStyle === "PRIMARY_BUTTON" && !disabled ? backgroundColor : "",
      };

      const borderStyleObjectOverride = getButtonBorderStyleObjectV1({
        border,
        buttonStyle,
        isLoading: isLoading,
        fallbackBorderColor: getFallbackBorderColor(
          buttonStyle,
          backgroundColor,
          theme.colors.primary500,
        ),
        isLoadingColor: theme.colors.neutral100,
        backgroundColorIfSet,
      });
      const hoverStyleObject = generateHoverVariableAssignmentStyleObject({
        borderStyleObject,
        backgroundColor:
          buttonStyle === "PRIMARY_BUTTON"
            ? backgroundColorIfSet
            : "transparent",
        fallbackBackgroundColor: getButtonThemeDefaultHoverBackgroundColor(
          theme,
          buttonStyle,
        ),
        textColor: textColorIfSet,
        fallbackTextColor: getButtonThemeDefaultHoverTextColor(
          theme,
          buttonStyle,
        ),
      });
      return {
        ...borderStyleObject,
        ...borderStyleObjectOverride,
        ...backgroundStyleObject,
        ...hoverStyleObject,
      };
    } else {
      const hoverStyleObject = generateHoverVariableAssignmentStyleObject({
        borderStyleObject,
        backgroundColor,
        textColor: textColorIfSet,
      });

      return {
        ...borderStyleObject,
        ...hoverStyleObject,
      };
    }
  }, [
    border,
    borderRadius,
    buttonStyle,
    isLoading,
    disabled,
    backgroundColor,
    backgroundColorIfSet,
    textColorIfSet,
    theme,
    version,
  ]);

  const buttonContent = useButtonContent({
    ...props,
    textColor:
      textColorIfSet ?? getButtonThemeDefaultTextColor(theme, buttonStyle),
  });

  return (
    <button
      disabled={props.disabled}
      className={classNames}
      style={{
        ...sharedStyles,
        ...buttonSpecificStyles,
      }}
      onClick={props.onClick}
      data-test={props["data-test"]}
    >
      {buttonContent}
      {props.isLoading && (
        <div
          className={ButtonLoader}
          style={{
            background: theme.colors.neutral100,
          }}
        >
          <Spinner size={20} />
        </div>
      )}
    </button>
  );
};

export const ButtonLikeLink = (props: ButtonLikeLinkProps) => {
  const {
    linkStyle,
    border,
    borderRadius,
    backgroundColor,
    textColor,
    textAlignment,
    isLoading,
    disabled,
  } = props;

  const containerClassNames = [
    SharedButtonClass,
    ButtonLikeLinkClass,
    isLoading ? "loading-button" : "",
    getTextAlignmentClass(textAlignment),
  ]
    .filter(Boolean)
    .join(" ");
  const anchorClassNames = [
    ButtonLikeLinkHoverClass,
    getClassNameForStyle(linkStyle),
    disabled ? CLASS_NAMES.DISABLED_MODIFIER : "",
  ]
    .filter(Boolean)
    .join(" ");

  const theme = useSelector(selectGeneratedTheme);
  const sharedStyles = useSharedStyles(props);
  const buttonLikeLinkSpecificStyles = useMemo(() => {
    const borderStyleObject = generateBorderStyleObject({
      border,
      borderRadius,
      fallbackBorderColor: theme.colors.primary500,
    });
    const hoverStyleObject = generateHoverVariableAssignmentStyleObject({
      borderStyleObject,
      backgroundColor,
      textColor,
    });
    const autoWidthWhenLink = linkStyle === "LINK" ? { width: "auto" } : {};

    return { ...borderStyleObject, ...hoverStyleObject, ...autoWidthWhenLink };
  }, [
    border,
    borderRadius,
    theme.colors.primary500,
    backgroundColor,
    textColor,
    linkStyle,
  ]);

  const forceTextColorWhenDisabled = linkStyle === "LINK";
  const buttonContent = useButtonContent(props, forceTextColorWhenDisabled);
  const href = props.url && !disabled ? props.url : undefined;
  return (
    <div className={containerClassNames}>
      <a
        href={href}
        className={anchorClassNames}
        target={props.openInNewTab ? "_blank" : "_self"}
        rel={props.openInNewTab ? "noopener noreferrer" : ""}
        draggable={false}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          }
          if (props.onClick) {
            props.onClick(e);
          }
        }}
        style={{ ...sharedStyles, ...buttonLikeLinkSpecificStyles }}
        data-test={props["data-test"]}
        data-content-width={props.width}
      >
        {buttonContent}
        {props.isLoading && (
          <div
            className={ButtonLoader}
            style={{
              background: theme.colors.neutral100,
            }}
          >
            <Spinner size={20} />
          </div>
        )}
      </a>
    </div>
  );
};
