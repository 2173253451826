import { get, pick } from "lodash";
import React, { useMemo } from "react";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import {
  selectGeneratedThemeFontFamily,
  selectGeneratedThemeTypographies,
} from "legacy/selectors/themeSelectors";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { extractPixels, getLineHeightInPixels } from "legacy/themes/utils";
import { useAppSelector } from "store/helpers";
import {
  createStyleClassName,
  getStylePropertyBasedOnVariant,
} from "./typographyUtils";
import type { Typographies, TextStyleBlock } from "@superblocksteam/shared";
import type { TextStyleWithVariant } from "legacy/themes";

const TO_OVERWRITE_KEYS: Record<keyof TextStyleWithVariant, boolean> = {
  fontFamily: true,
  fontSize: true,
  fontWeight: true,
  letterSpacing: true,
  lineHeight: true,
  textDecoration: true,
  textTransform: true,
  fontStyle: true,
  textColor: false,
  variant: false,
};

const DIRECT_PROPERTIES_TO_OVERWRITE = Object.entries(TO_OVERWRITE_KEYS)
  .filter(([_, shouldInclude]) => shouldInclude)
  .map(([propertyName]) => propertyName as keyof TextStyleWithVariant);

export type TypographyProps = {
  textStyleVariant: string;
  style?: React.CSSProperties;
};

export function useTypographyStyling({
  baseStyle,
  priorityStyle,
  textStyle,
  defaultTextStyleVariant,
  forceTextStyleVariant,
  skipTextStyleColorDefaultOverwrite = false,
  applyClassNameStylesToStyle = false,
}: {
  baseStyle?: React.CSSProperties;
  priorityStyle?: React.CSSProperties; // Only applied when typography is enabled. Useful for overrides (e.g: color)
  textStyle?: TextStyleWithVariant;
  defaultTextStyleVariant: keyof Typographies | typeof SB_CUSTOM_TEXT_STYLE;
  forceTextStyleVariant?: // Useful when working with widgets that have a legacy textStyle variant such as TextWidget
  keyof Typographies | typeof SB_CUSTOM_TEXT_STYLE;
  skipTextStyleColorDefaultOverwrite?: boolean; // By default we set the CSS color property with the given textStyle.textColor.default value. This can be skipped.
  applyClassNameStylesToStyle?: boolean; // Take the style properties normally applied using the CLASS_NAMES and instead apply them using a style prop. Useful when dealing with external libs such as blueprint where their CSS normally takes precedence over our theme
}): TypographyProps {
  const defaultTypography = useAppSelector(selectGeneratedThemeFontFamily);

  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);

  return useMemo(() => {
    let textStyleVariant = forceTextStyleVariant ?? textStyle?.variant;

    // Use defaultTextStyleVariant if no textStyleVariant, or if the custom typography was deleted
    if (
      !textStyleVariant ||
      (textStyleVariant !== SB_CUSTOM_TEXT_STYLE &&
        get(themeTypographies, textStyleVariant) == null)
    ) {
      textStyleVariant = defaultTextStyleVariant;
    }

    const stylesToOverwrite =
      textStyleVariant === SB_CUSTOM_TEXT_STYLE
        ? pick(textStyle, DIRECT_PROPERTIES_TO_OVERWRITE)
        : {};

    const textColor =
      !skipTextStyleColorDefaultOverwrite && textStyle?.textColor?.default
        ? { color: textStyle?.textColor?.default }
        : {};

    let appliedStylesFromClassName: React.CSSProperties = {};

    if (applyClassNameStylesToStyle && textStyleVariant) {
      const {
        textColor: fetchedTextColor,
        ...fetchedTypography
      }: Partial<TextStyleBlock> =
        get(themeTypographies, textStyleVariant) ?? {};
      appliedStylesFromClassName = {
        ...fetchedTypography,
        color: fetchedTextColor?.default,
      };
    }

    // We are merging CSSProperties along with our pick of TextStyleWithVariant
    // By design, the elements we've picked from TextStyleWithVariant map 1:1 with CSSProperties.
    // We handle the textColor separately because of it.
    const style: React.CSSProperties = {
      ...baseStyle,
      ...stylesToOverwrite,
      ...appliedStylesFromClassName,
      ...textColor,
      ...priorityStyle,
    };
    if (style.fontFamily && style.fontFamily !== "inherit") {
      // add a fallback to the theme default
      style.fontFamily += `, ${defaultTypography}`;
    }

    return {
      textStyleVariant,
      style,
    };
  }, [
    baseStyle,
    priorityStyle,
    defaultTextStyleVariant,
    textStyle,
    forceTextStyleVariant,
    themeTypographies,
    skipTextStyleColorDefaultOverwrite,
    defaultTypography,
    applyClassNameStylesToStyle,
  ]);
}

export const useStyleClassNames = (
  params: Parameters<typeof createStyleClassName>[0],
) => {
  const { textStyleVariant, isLoading, isDisabled, type } = params;
  return useMemo(() => {
    return createStyleClassName({
      textStyleVariant,
      isLoading,
      isDisabled,
      type,
    });
  }, [textStyleVariant, isLoading, isDisabled, type]);
};

export const useMinHeightFromTypography = ({
  isAutoHeight,
  isAutoWidth,
  textStyleVariant,
  textStyle,
  defaultTextStyleVariant,
}: {
  isAutoHeight: boolean;
  isAutoWidth: boolean;
  textStyleVariant: keyof Typographies | typeof SB_CUSTOM_TEXT_STYLE;
  defaultTextStyleVariant: keyof Typographies;
  textStyle?: TextStyleWithVariant | undefined;
}) => {
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);
  if (isAutoHeight && !isAutoWidth) {
    const lineHeight = getStylePropertyBasedOnVariant({
      typographies: themeTypographies,
      textStyleVariant,
      propName: "lineHeight",
      defaultTextStyleVariant,
      nestedProps: textStyle,
    });
    const fontSize = getStylePropertyBasedOnVariant({
      typographies: themeTypographies,
      textStyleVariant,
      propName: "fontSize",
      defaultTextStyleVariant,
      nestedProps: textStyle,
    });
    const lineHeightPx = getLineHeightInPixels(
      lineHeight,
      extractPixels(fontSize),
    );
    const minGridHeight = Math.max(
      Math.ceil(lineHeightPx / GridDefaults.DEFAULT_GRID_ROW_HEIGHT),
      1,
    );
    return (
      minGridHeight * GridDefaults.DEFAULT_GRID_ROW_HEIGHT - WIDGET_PADDING * 2
    );
  }
};
