import { BranchDto } from "@superblocksteam/shared";
import { call, put, select } from "redux-saga/effects";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  ProfilesWithModes,
  getWorkflowProfiles,
} from "legacy/selectors/applicationSelectors";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { ROOT } from "store/utils/types";

import { createSaga, SagaType } from "../../../utils/saga";

import { getApiV3 } from "../client";
import slice from "../slice";
import { ApiDto } from "../types";

interface FetchApiPayload {
  apiId: string;
  environment: string;
}

function* fetchApi({ apiId, environment }: FetchApiPayload) {
  // This is called in workflow/job only to fetch workflow/job
  // get profile given mode

  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );
  const profiles: ProfilesWithModes = yield select(getWorkflowProfiles, apiId);
  const profile = profiles?.editor.default;

  const branch = (yield select(getCurrentBranch)) as BranchDto | undefined;

  const result: ApiDto = yield call(getApiV3, {
    apiId,
    ...(enableProfiles ? { profile } : { environment }),
    ...(branch ? { branch: branch.name } : {}),
  });

  yield put({
    type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    payload: result.updated,
  });

  return result;
}

export const fetchApiSaga = createSaga(fetchApi, "fetchApiSaga", {
  sliceName: "apis",
  type: SagaType.Throttled,
  delay: 1500,
});

slice.saga(fetchApiSaga, {
  start(state) {
    state.loading[ROOT] = true;
    // TODO errors
  },
  success(state, { payload }) {
    state.entities = { [payload.id]: payload };
    delete state.loading[ROOT];
    state.meta[payload.id] = state.meta[payload.id] ?? {};
    state.meta[payload.id].needsBindingExtraction = true;
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
});
