import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { AppTimer } from "./TimerConstants";

interface RenameTimersPayload {
  updates: Array<{
    timerId: string;
    propertyName: string;
    propertyValue: unknown;
    scope: ApplicationScope;
  }>;
}

export const createTimer = createAction(
  "CREATE_TIMER",
  (
    scope: ApplicationScope,
    name?: AppTimer["name"],
    id?: AppTimer["id"],
    openInPropertyPane?: boolean,
  ) => ({
    payload: {
      name,
      scope,
      id,
      openInPropertyPane,
    },
  }),
);

export const deleteTimer = createAction(
  "DELETE_TIMER",
  (scope: ApplicationScope, id: string) => ({
    payload: {
      id,
      scope,
    },
  }),
);

export const updateTimers = createAction(
  "UPDATE_TIMERS",
  (
    payload: Record<
      AppTimer["id"],
      {
        updates: Partial<
          Pick<AppTimer, "startOnPageLoad" | "intervalMs" | "steps">
        >;
        scope: ApplicationScope;
      }
    >,
  ) => ({ payload }),
);

export const renameTimers = createAction(
  "RENAME_TIMERS",
  (updates: RenameTimersPayload["updates"]) => ({
    payload: { updates },
  }),
);

export const startTimer = createAction(
  "START_TIMER",
  (scope: ApplicationScope, name: AppTimer["name"]) => ({
    payload: {
      name,
      scope,
    },
  }),
);

export const stopTimer = createAction(
  "STOP_TIMER",
  (scope: ApplicationScope, name: AppTimer["name"], isPause?: boolean) => ({
    payload: {
      name,
      isPause,
      scope,
    },
  }),
);

export const toggleTimer = createAction(
  "TOGGLE_TIMER",
  (scope: ApplicationScope, name: AppTimer["name"]) => ({
    payload: {
      name,
      scope,
    },
  }),
);

export const restartTimer = createAction(
  "RESTART_TIMER",
  (id: AppTimer["id"]) => ({
    payload: {
      id,
    },
  }),
);

export const restartTimers = createAction(
  "RESTART_TIMERS",
  (timerIds: AppTimer["id"][]) => ({
    payload: {
      timerIds,
    },
  }),
);

export const deleteEntityFromTimers = createAction(
  "DELETE_ENTITY_FROM_TIMERS",
  (entityName: string, isWidget?: boolean) => ({
    payload: {
      entityName,
      isWidget: isWidget !== undefined ? isWidget : false,
    },
  }),
);

export const pauseAllTimers = createAction("PAUSE_ALL_TIMERS", () => ({
  payload: {},
}));

export const restartAllPausedTimers = createAction(
  "RESTART_ALL_PAUSED_TIMERS",
  () => ({
    payload: {},
  }),
);

export const duplicateTimer = createAction(
  "DUPLICATE_TIMER",
  ({
    fromScope,
    toScope,
    timerId,
  }: {
    toScope: ApplicationScope;
    fromScope: ApplicationScope;
    timerId: string;
  }) => ({
    payload: {
      timerId,
      fromScope,
      toScope,
    },
  }),
);

export const setCreatingTimer = createAction(
  "SET_CREATING_TIMER",
  (payload: boolean) => ({ payload }),
);

export const editTimerPropertyPane = (
  timerId: string,
  scope: ApplicationScope,
) => showItemPropertyPane({ kind: ItemKinds.TIMER, id: timerId, scope });
