import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const LinkStylesString = `
  color: ${colors.ACCENT_BLUE_600};
  cursor: pointer;
  &:hover {
    color: ${colors.ACCENT_BLUE_700};
    text-decoration: none;
  }
`;
const UnderlinedString = `
  &:hover {
    text-decoration: underline;
  }
`;

const LinkCss = css`
  ${LinkStylesString}
`;

const UnderlinedLinkCss = css`
  ${LinkStylesString}
  ${UnderlinedString}
`;

export const UnderlinedLinkClass = styleAsClass`
  ${LinkStylesString}
  ${UnderlinedString}
`;

export const PlainLink = styled.a`
  ${LinkCss}
  height: 100%;
`;

export const UnderlinedPlainLink = styled.a`
  ${UnderlinedLinkCss}
  height: 100%;
`;

export default styled(Link)`
  ${LinkCss}
`;
