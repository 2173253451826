import {
  NumericInput,
  InputGroup,
  Button,
  Label,
  Classes,
  ControlGroup,
  TextArea,
} from "@blueprintjs/core";
import { Dimension, Padding } from "@superblocksteam/shared";
import Decimal from "decimal.js";
import { debounce } from "lodash";
import memoizeOne from "memoize-one";
import React from "react";
import { flushSync } from "react-dom";
import shallowEqual from "shallowequal";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import ErrorTooltip from "legacy/components/editorComponents/ErrorTooltip";
import { Layers } from "legacy/constants/Layers";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { INPUT_PADDING } from "legacy/themes/constants";
import { formatNumber, formatPercentage } from "legacy/utils/FormatUtils";
import {
  InputTypes,
  type InputType,
  currencySymbolMap,
} from "legacy/widgets/InputWidget/InputWidgetConstants";
import { styleAsClass } from "styles/styleAsClass";
import { labelStyleRaw } from "../Shared/widgetLabelStyles";
import {
  createCssVariablesForBorderWidthAndColorWithHover,
  generateCssForBorderWidthAndColorWithHover,
} from "../base/generateBorderCss";
import { generateBorderRadiusStyleObject } from "../base/generateBorderStyle";
import {
  generatePaddingVariableAssignmentStyleObject,
  generatePaddingVariableDeclarationCss,
} from "../base/generatePaddingStyle";
import { InputComponentProps } from "./types";

Decimal.set({
  toExpPos: 9e15,
  toExpNeg: -9e15,
});

export const hasIconSupport = (inputType: InputType) => {
  return ![InputTypes.CURRENCY, InputTypes.PASSWORD].includes(inputType);
};

/**
 * All design system component specific logic goes here.
 * Ex. Blueprint has a separate numeric input and text input so switching between them goes here
 * Ex. To set the icon as currency, blue print takes in a set of defined types
 * All generic logic like max characters for phone numbers should be 10, should go in the widget
 */

export const isNumericInput = (inputType: InputType) => {
  return (
    inputType === InputTypes.INTEGER ||
    inputType === InputTypes.NUMBER ||
    inputType === InputTypes.CURRENCY ||
    inputType === InputTypes.PERCENTAGE
  );
};

const ICON_WIDTH = 22;
const STEPPER_WIDTH = 24;

const NumericInputWrapperClass = styleAsClass`
  display: flex;
  width: 100%;
  height: 100%;

  .prefix {
    border-right: 1px solid var(--default-border-color);
  }
  .suffix {
    border-left: 1px solid var(--default-border-color);
  }

  .currency-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: ${String(STEPPER_WIDTH)}px;
    flex-shrink: 0;

    border-top: 0;
    border-bottom: 0;
    border-left: 0;
  }

  .stepper-wrapper {
    display: flex;
    flex-direction: column;

    .stepper-button {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      padding: 0;
      border: none;
      flex: 1;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .stepper-increment {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      border-bottom: 1px solid var(--default-border-color);

      svg {
        transform: rotate(180deg);
      }
    }
    .stepper-decrement {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
`;

const ControlGroupClassName = styleAsClass`
  position: relative;
  overflow: hidden;
  &[data-has-prefix="true"] {
    &&&& {
      .${Classes.NUMERIC_INPUT} .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        border-radius: 0 !important;
      }
    }
  }

  &[data-show-stepper="true"] {
    &&&&& {
      .${Classes.NUMERIC_INPUT} .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        border-radius: 0 !important;
      }
    }
  }

  &[data-multiline="true"] {
    .${Classes.POPOVER_TARGET} {
      height: 100%;
    }
    &&&& {
      .${Classes.INPUT} {
        height: 100%;
        max-height: 100%;
      }
    }
    &[data-vertical="false"] {
      &&&& {
        .${Classes.INPUT} {
          width: calc(100% - 1px);
        }
      }
    }
  }
  &[data-vertical="true"] {
    &&&& {
      label {
        ${labelStyleRaw.vertical}
        flex: 0 0 auto;
        max-width: 100%;
      }
      .${Classes.INPUT_GROUP} {
        width: 100%;
      }
      div {
        margin-top: 0px;
      }
    }
  }
  &:not([data-vertical="true"]) {
    &&&& {
      label {
        ${labelStyleRaw.horizontal}
        flex: 0 0 30%;
        max-width: calc(30% - ${String(WIDGET_PADDING)}px);

        text-align: left;
        align-self: flex-start;
      }
      div {
        margin-right: 0px;
      }
    }
  }

  &&&& {
    .${Classes.INPUT_ACTION} {
      height: 100%;
      display: flex;
    }

    .${Classes.INPUT_ACTION} .${Classes.BUTTON} {
      margin: 0;
      background: none;
      box-shadow: none;
      width: ${String(ICON_WIDTH)}px;
      min-height: 22px;
    }
    .${Classes.NUMERIC_INPUT} .${Classes.BUTTON_GROUP} {
      display: none;
    }
    .${Classes.INPUT} {
      width: 100%;
      height: 100%;
    }
    .${Classes.INPUT_GROUP} {
      display: block;
      margin: 0;
    }
    .${Classes.CONTROL_GROUP} {
      justify-content: flex-start;
    }
    height: 100%;

    textarea {
      resize: none;
    }
  }

  .IconWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 99;
  }
`;

const STYLED_BORDERS_CLASS_NAME = "inputWithStyledBorders";

const SupportNestedStyledBorders = styleAsClass`${generateCssForBorderWidthAndColorWithHover(
  {
    selectors: [
      `.${STYLED_BORDERS_CLASS_NAME}.${CLASS_NAMES.INPUT} > input`, // text input
      `.${STYLED_BORDERS_CLASS_NAME} > .${CLASS_NAMES.INPUT} > .${Classes.INPUT_GROUP} > input`, // numeric input
      `textarea.${STYLED_BORDERS_CLASS_NAME}.${CLASS_NAMES.INPUT}.${Classes.INPUT}`, // multi line text input
    ],
  },
)}`;

// InputGroup with prefix/suffix addons (currently only used for Numbers input with currency or stepper)
const InputGroupWithStyledBorders = styleAsClass`
  overflow: hidden; // clips prefix/suffix addons when using rounded borders

  .${Classes.INPUT_GROUP} > input {
    border: none; // we treat the InputGroup as the input, so the inner input must lose its border styling
  }

  ${generateCssForBorderWidthAndColorWithHover({
    selectors: "&",
  })}
`;

const SupportNestedStyledPadding = styleAsClass`
  &&&& .${Classes.INPUT} {
    ${generatePaddingVariableDeclarationCss()}
  }
`;

class InputComponent extends React.Component<
  InputComponentProps,
  InputComponentState
> {
  textareaRef: React.RefObject<TextArea>;
  numericInputRef: React.RefObject<NumericInput>;
  inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: InputComponentProps) {
    super(props);
    let value = props.value;
    let displayValue = props.value;
    // For TABLE column type, number/percentage/currency are all of inputType as NUMBER
    if (isNumericInput(props.inputType)) {
      value = String(
        props.inputType === InputTypes.PERCENTAGE &&
          props.value !== "" &&
          !isNaN(Number(props.value))
          ? new Decimal(props.value).times(100)
          : props.value,
      );
      value = this.valueWithPrecision(value);
      displayValue = this.formatNumber(value || "");
    }
    this.state = {
      localValue: value,
      displayValue,
      showPassword: false,
      inputFocused: false,
    };
    this.textareaRef = React.createRef<TextArea>();
    this.numericInputRef = React.createRef<NumericInput>();
    this.inputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount(): void {
    this.ensureFocus();
  }

  componentDidUpdate(prevProps: Readonly<InputComponentProps>) {
    const {
      value,
      inputType,
      defaultValue,
      numberFormatting,
      minimumFractionDigits,
      maximumFractionDigits,
      preventFormattingWhileTyping,
    } = this.props;
    // prevent re-formatting on external value updates when the input is focused
    if (preventFormattingWhileTyping && this.state.inputFocused) return;
    const isPercentInput = inputType === InputTypes.PERCENTAGE;
    if (
      prevProps.numberFormatting !== numberFormatting ||
      prevProps.minimumFractionDigits !== minimumFractionDigits ||
      prevProps.maximumFractionDigits !== maximumFractionDigits ||
      prevProps.inputType !== inputType ||
      prevProps.defaultValue !== defaultValue ||
      prevProps.value !== value
    ) {
      if (isNumericInput(inputType)) {
        const number = String(
          isPercentInput && value !== "" && !isNaN(Number(value))
            ? new Decimal(value).times(100)
            : value,
        );
        const numberWithPrecision = this.valueWithPrecision(number);
        const formatted = this.formatNumber(numberWithPrecision);
        this.setState({
          localValue: numberWithPrecision,
          displayValue: formatted,
        });
      } else {
        this.setState({
          localValue: value,
          displayValue: value,
        });
      }
    }
  }

  componentWillUnmount() {
    this.onDebouncedValueChange.flush();
  }

  shouldComponentUpdate(
    prevProps: Readonly<InputComponentProps>,
    prevState: Readonly<InputComponentState>,
  ) {
    return (
      !shallowEqual(prevProps, this.props) ||
      !shallowEqual(prevState, this.state)
    );
  }

  onDebouncedValueChange = debounce(
    (value: any) => this.props.onValueChange(value),
    this.props.disableDebounce ? 0 : 300,
  );

  getInputDomElement = () => {
    if (isNumericInput(this.props.inputType)) {
      return this.numericInputRef.current?.inputElement;
    } else if (!this.props.multiline) {
      return this.inputRef.current;
    } else {
      return this.textareaRef.current?.textareaElement;
    }
  };

  ensureFocus = () => {
    if (!this.props.autoFocus || this.state.inputFocused) return;

    let inputElement: HTMLInputElement | null | undefined = null;
    if (isNumericInput(this.props.inputType)) {
      inputElement = this.numericInputRef.current?.inputElement;
    } else if (!this.props.multiline) {
      inputElement = this.inputRef.current;
    } else {
      const element = this.textareaRef.current?.textareaElement;
      if (this.state.inputFocused && document.activeElement === element) {
        return;
      }

      if (element && typeof element.setSelectionRange === "function") {
        const endPos = element.value?.length;
        element.focus({ preventScroll: true });
        element.setSelectionRange(endPos, endPos);
      }
    }

    if (!inputElement) return;

    if (this.state.inputFocused && document.activeElement !== inputElement) {
      inputElement.focus({ preventScroll: true });
    }
  };

  onFocus = () => {
    this.setState({ inputFocused: true });
    this.props.onFocusChange(true);

    if (isNumericInput(this.props.inputType)) {
      this.setState({
        displayValue: this.state.localValue,
      });
    }
  };

  onBlur = () => {
    this.setState({ inputFocused: false });

    this.onDebouncedValueChange.flush();
    this.props.onFocusChange(false);

    if (isNumericInput(this.props.inputType)) {
      const valueWithPrecision = this.valueWithPrecision(
        this.state.localValue || "",
      );
      const formatted = this.formatNumber(valueWithPrecision);
      this.setState({
        displayValue: formatted,
        localValue: valueWithPrecision,
      });
    } else {
      this.setState({
        displayValue: this.props.value,
        localValue: this.props.value,
      });
    }
  };

  formatNumber = (value: string) => {
    if (
      this.props.inputType === InputTypes.PERCENTAGE &&
      value !== "" &&
      !isNaN(Number(value))
    )
      return formatPercentage(
        new Decimal(value).div(100).toString(),
        this.props.minimumFractionDigits,
        this.props.maximumFractionDigits,
      );
    return formatNumber(
      value,
      this.props.numberFormatting,
      this.props.minimumFractionDigits,
      this.props.maximumFractionDigits,
    );
  };

  valueWithPrecision = (value: string | number) => {
    const { minimumFractionDigits, maximumFractionDigits, inputType } =
      this.props;
    const isPercent = inputType === InputTypes.PERCENTAGE;
    // show raw value instead of percentage when focused, we need to add two decimal places
    const inRawValueWhenFocused = this.props.widgetType === "TABLE_WIDGET";
    const extraDecimalPlaces = inRawValueWhenFocused ? 2 : 0;
    const valueWithPrecision = formatNumber(
      value,
      "standard",
      isPercent && minimumFractionDigits
        ? Number(minimumFractionDigits) + extraDecimalPlaces
        : minimumFractionDigits,
      isPercent && maximumFractionDigits
        ? Number(maximumFractionDigits) + extraDecimalPlaces
        : maximumFractionDigits,
    );
    return valueWithPrecision.replaceAll(",", "");
  };

  onTextChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = isNumericInput(this.props.inputType)
      ? this.valueWithPrecision(event.target.value)
      : event.target.value;
    this.setState({ localValue: value });
    this.onDebouncedValueChange(value);
  };

  onNumberChange = (valueAsNum: number, valueAsString: string) => {
    this.setState({
      localValue: valueAsString,
      displayValue: valueAsString,
    });
    // change precision first and then div 100 to keep real precision
    const valueWithPrecisionAsString = this.valueWithPrecision(valueAsString);
    // preserve empty string instead of 0 if input is empty
    const numberString =
      this.props.inputType === InputTypes.PERCENTAGE &&
      valueWithPrecisionAsString
        ? String(new Decimal(valueWithPrecisionAsString).div(100))
        : valueWithPrecisionAsString;
    this.onDebouncedValueChange(numberString);
  };

  getIcon(inputType: InputType) {
    switch (inputType) {
      case "PHONE_NUMBER":
        return "phone";
      case "SEARCH":
        return "search";
      case "EMAIL":
        return "envelope";
      default:
        return undefined;
    }
  }

  getType(inputType: InputType) {
    switch (inputType) {
      case "PASSWORD":
        return this.state.showPassword ? "text" : "password";
      case "EMAIL":
        return "email";
      case "SEARCH":
        return "search";
      default:
        return "text";
    }
  }

  onKeyDownTextArea = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isEnterKey = e.key === "Enter" || e.keyCode === 13;
    const { disableNewLineOnPressEnterKey } = this.props;
    if (isEnterKey && disableNewLineOnPressEnterKey && !e.shiftKey) {
      e.preventDefault();
    }
    if (isEnterKey) {
      this.onDebouncedValueChange.flush();
    }
    if (typeof this.props.onKeyDown === "function") {
      this.props.onKeyDown(e);
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterKey = e.key === "Enter" || e.keyCode === 13;
    if (isEnterKey) {
      this.onDebouncedValueChange.flush();
    }
    if (typeof this.props.onKeyDown === "function") {
      this.props.onKeyDown(e);
    }
  };

  handleIncrementDecrement = (isIncrementing: boolean) => {
    const { stepSize, inputType } = this.props;
    const isPercentInput = inputType === InputTypes.PERCENTAGE;
    if (stepSize && (stepSize as unknown) !== "" && !isNaN(Number(stepSize))) {
      const currentValue = new Decimal(this.state.localValue || "");
      const updatedNumber = currentValue
        .plus(Number(stepSize) * (isIncrementing ? 1 : -1))
        .toString();

      this.setState({
        localValue: updatedNumber,
        displayValue: this.formatNumber(updatedNumber),
      });
      const valueWithPrecision = this.valueWithPrecision(
        String(
          isPercentInput ? new Decimal(updatedNumber).div(100) : updatedNumber,
        ),
      );
      this.onDebouncedValueChange(valueWithPrecision);
    }
  };

  private getClassNames = (withStyledBorders: boolean = true) => {
    return `${CLASS_NAMES.INPUT} ${this.props.inputClassName ?? ""} ${
      this.props.isInvalid ? CLASS_NAMES.ERROR_MODIFIER : ""
    } ${this.props.isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""} ${
      this.props.isLoading ? "bp5-skeleton" : ""
    } ${isNumericInput(this.props.inputType) ? Classes.FILL : ""} ${
      withStyledBorders ? STYLED_BORDERS_CLASS_NAME : ""
    }`;
  };

  private hasSuffix = () => {
    return this.props.showStepper && isNumericInput(this.props.inputType);
  };

  private hasPrefix = () => {
    return this.props.inputType === InputTypes.CURRENCY;
  };

  private isInputGroupWithAddons = () => {
    return this.hasPrefix() || this.hasSuffix();
  };

  /**
   * Generates a style object for the input element and input group.
   * We only use the style props for border radius, background color, text style and icon position styling.
   * Border, border color, border width, and padding styling is handled by the CSS classes.
   *
   * @param inputGroupStylingProps - The input group styling properties.
   * @param inputStyleOverride - The input style override properties.
   * @returns styles for to be used either by an input element or an input group element.
   */
  private static generateStyleObject = memoizeOne(
    (
      inputGroupStylingProps: InputComponentProps["inputGroupStylingProps"],
      inputStyleOverride: InputComponentProps["inputStyleOverride"],
      iconPosition?: "LEFT" | "RIGHT",
      hasSuffix?: boolean,
    ) => {
      const radiusObject = generateBorderRadiusStyleObject(
        inputGroupStylingProps ?? {},
      );
      const iconPositionObject = InputComponent.generateIconPositionStyleObject(
        {
          inputGroupStylingProps,
          iconPosition,
          hasSuffix,
        },
      );

      return {
        inputElement: {
          ...inputStyleOverride,
          ...radiusObject,
        },
        inputGroup: radiusObject,
        icon: iconPositionObject,
      };
    },
  );

  private static generateIconPositionStyleObject = ({
    inputGroupStylingProps,
    iconPosition,
    hasSuffix,
  }: {
    inputGroupStylingProps: InputComponentProps["inputGroupStylingProps"];
    iconPosition?: "LEFT" | "RIGHT";
    hasSuffix?: boolean;
  }) => {
    const padding = inputGroupStylingProps?.padding ?? INPUT_PADDING;
    const border = inputGroupStylingProps?.border;
    const defaultBorderWidth =
      inputGroupStylingProps?.defaultBorderWidth?.value ?? 0;
    let offsetLeft: string | number = "auto";
    let offsetRight: string | number = "auto";

    if (iconPosition === "LEFT") {
      offsetLeft =
        (padding.left?.value ?? 0) +
        (border?.left?.width?.value ?? defaultBorderWidth);
    } else if (hasSuffix) {
      offsetRight =
        STEPPER_WIDTH +
        (padding.right?.value ?? 0) +
        (border?.right?.width?.value ?? defaultBorderWidth);
    } else {
      offsetRight =
        (padding.right?.value ?? 0) +
        (border?.right?.width?.value ?? defaultBorderWidth);
    }

    return {
      left: offsetLeft,
      right: offsetRight,
    };
  };

  private getStyleObject = () => {
    return InputComponent.generateStyleObject(
      this.props.inputGroupStylingProps,
      this.props.inputStyleOverride,
      !!this.props.icon && hasIconSupport(this.props.inputType)
        ? this.props.iconPosition
        : undefined,
      this.hasSuffix(),
    );
  };

  private createCssVariablesForPadding = memoizeOne(
    (
      padding: Padding,
      iconPosition?: "LEFT" | "RIGHT" | undefined,
    ): { [key: `--${string}`]: string } => {
      let adjustedForIcon = {};

      if (iconPosition === "LEFT") {
        adjustedForIcon = {
          left: Dimension.add(
            Dimension.px(ICON_WIDTH),
            padding.left ?? Dimension.px(0),
          ).asFirst(),
        };
      } else if (iconPosition === "RIGHT") {
        adjustedForIcon = {
          right: Dimension.add(
            Dimension.px(ICON_WIDTH),
            padding.right ?? Dimension.px(0),
          ).asFirst(),
        };
      }

      return generatePaddingVariableAssignmentStyleObject({
        ...padding,
        ...adjustedForIcon,
      });
    },
  );

  private numericInputComponent = () => {
    const hasSuffix = this.hasSuffix();
    const hasPrefix = this.hasPrefix();

    const padding = this.props.inputGroupStylingProps?.padding ?? INPUT_PADDING;

    /**
     * Inputs with prefix and suffix addons behave differently than regular inputs and don't inherit theme styles.
     * We need to style the input group as if it were an input element.
     */
    const inputStyles = this.isInputGroupWithAddons()
      ? this.props.inputStyleOverride
      : this.getStyleObject().inputElement;

    const wrapperStyles = this.isInputGroupWithAddons()
      ? this.getStyleObject().inputGroup
      : undefined;

    const wrapperClassName = this.isInputGroupWithAddons()
      ? InputGroupWithStyledBorders
      : STYLED_BORDERS_CLASS_NAME;

    return (
      <div
        className={`${NumericInputWrapperClass} ${wrapperClassName}`}
        style={wrapperStyles}
      >
        {hasPrefix && (
          <div className={`currency-prefix prefix ${CLASS_NAMES.SYSTEM_TEXT}`}>
            <span>
              {this.props.currencyCodeDisplay === "symbol" &&
              this.props.currency
                ? (currencySymbolMap[this.props.currency] ??
                  this.props.currency)
                : this.props.currency}
            </span>
          </div>
        )}
        <PaddingOverlayWrapper
          widgetId={this.props.widgetId}
          padding={padding}
          appMode={this.props.appMode}
        >
          <NumericInput
            value={this.state.displayValue}
            placeholder={this.props.placeholder}
            disabled={this.props.isDisabled}
            intent={this.props.intent}
            className={this.getClassNames(false)}
            onValueChange={this.onNumberChange}
            leftIcon={
              this.props.inputType === InputTypes.PHONE_NUMBER
                ? "phone"
                : this.props.leftIcon
            }
            type={this.props.inputType === "PHONE_NUMBER" ? "tel" : undefined}
            stepSize={this.props.stepSize}
            minorStepSize={(this.props.stepSize ?? 1) / 10}
            majorStepSize={(this.props.stepSize ?? 1) * 10}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            ref={this.numericInputRef}
            autoFocus={this.props.autoFocus}
            style={inputStyles}
          />
        </PaddingOverlayWrapper>
        {hasSuffix && (
          <div className="stepper-wrapper suffix">
            <button
              aria-label="increment"
              className={`${CLASS_NAMES.SYSTEM_BUTTON} stepper-button stepper-increment`}
              onClick={() => this.handleIncrementDecrement(true)}
            >
              <ChevronDown />
            </button>
            <button
              aria-label="decrement"
              className={`${CLASS_NAMES.SYSTEM_BUTTON} stepper-button stepper-decrement`}
              onClick={() => this.handleIncrementDecrement(false)}
            >
              <ChevronDown />
            </button>
          </div>
        )}
      </div>
    );
  };

  private textAreaInputComponent = () => {
    const padding = this.props.inputGroupStylingProps?.padding ?? INPUT_PADDING;
    return (
      <PaddingOverlayWrapper
        widgetId={this.props.widgetId}
        padding={padding}
        appMode={this.props.appMode}
      >
        <TextArea
          value={this.state.localValue}
          placeholder={this.props.placeholder}
          disabled={this.props.isDisabled}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          intent={this.props.intent}
          onChange={this.onTextChange}
          className={this.getClassNames()}
          growVertically={this.props.growVertically}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDownTextArea}
          rows={this.props.numLines}
          ref={this.textareaRef}
          style={this.getStyleObject().inputElement}
        />
      </PaddingOverlayWrapper>
    );
  };

  private passwordIconComponent = () => {
    return (
      <Button
        icon={
          <DynamicSVG
            iconName={this.state.showPassword ? "visibility_off" : "visibility"}
            size={16}
          />
        }
        style={this.getStyleObject().icon}
        data-test="lock-icon"
        onClick={() => {
          flushSync(() => {
            this.setState({ showPassword: !this.state.showPassword });
          });
        }}
      />
    );
  };

  private textInputComponent = (isTextArea: boolean) =>
    isTextArea ? (
      this.textAreaInputComponent()
    ) : (
      <PaddingOverlayWrapper
        widgetId={this.props.widgetId}
        padding={this.props.inputGroupStylingProps?.padding ?? INPUT_PADDING}
        appMode={this.props.appMode}
      >
        <InputGroup
          autoFocus={this.props.autoFocus}
          value={this.state.localValue}
          placeholder={this.props.placeholder}
          disabled={this.props.isDisabled}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          intent={this.props.intent}
          onChange={this.onTextChange}
          className={this.getClassNames()}
          style={this.getStyleObject().inputElement}
          fill
          rightElement={
            this.props.inputType === "PASSWORD"
              ? this.passwordIconComponent()
              : undefined
          }
          type={this.getType(this.props.inputType)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          inputRef={this.inputRef}
        />
      </PaddingOverlayWrapper>
    );

  private renderInputComponent = (inputType: InputType, isTextArea: boolean) =>
    isNumericInput(inputType)
      ? this.numericInputComponent()
      : this.textInputComponent(isTextArea);

  private renderIcon = () => {
    return (
      <div
        style={this.getStyleObject().icon}
        className={`IconWrapper ${CLASS_NAMES.ICON} ${
          this.state.inputFocused ? CLASS_NAMES.ACTIVE_MODIFIER : ""
        } ${
          this.props.isDisabled ||
          this.props.value === "" ||
          this.props.value == null
            ? CLASS_NAMES.DISABLED_MODIFIER
            : ""
        }`}
      >
        <DynamicSVG iconName={this.props.icon} size={18} />
      </div>
    );
  };

  render() {
    const hasIcon = !!this.props.icon && hasIconSupport(this.props.inputType);
    const borderVarDeclarations = this.props.inputGroupStylingProps
      ? createCssVariablesForBorderWidthAndColorWithHover({
          border: this.props.inputGroupStylingProps.border,
          fallbackBorderColor:
            this.props.inputGroupStylingProps.fallbackBorderColor,
          borderColorOnHover:
            this.props.inputGroupStylingProps.borderColorOnHover,
          defaultBorderWidth:
            this.props.inputGroupStylingProps.defaultBorderWidth,
        })
      : {};
    const paddingVarDeclarations = this.createCssVariablesForPadding(
      this.props.inputGroupStylingProps?.padding ?? INPUT_PADDING,
      hasIcon ? this.props.iconPosition : undefined,
    );

    return (
      <ControlGroup
        style={{ ...borderVarDeclarations, ...paddingVarDeclarations }}
        className={`${ControlGroupClassName} ${SupportNestedStyledBorders} ${SupportNestedStyledPadding}`}
        data-has-prefix={this.props.inputType === InputTypes.CURRENCY}
        data-show-stepper={this.props.showStepper}
        data-multiline={this.props.multiline}
        data-vertical={this.props.vertical}
        vertical={this.props.vertical}
        fill
        data-left-icon={hasIcon && this.props.iconPosition === "LEFT"}
        data-right-icon={hasIcon && this.props.iconPosition === "RIGHT"}
      >
        {this.props.label ? (
          <Label
            className={`${CLASS_NAMES.ELLIPSIS_TEXT} ${
              this.props.isLoading ? Classes.SKELETON : ""
            } ${this.props.labelClassName ?? CLASS_NAMES.INPUT_LABEL} ${
              this.props.isDisabled ? CLASS_NAMES.DISABLED_MODIFIER : ""
            }
            `}
            style={this.props.labelStyleOverride}
          >
            {this.props.isRequired && this.props.label.indexOf("*") === -1 && (
              <span className={`asterisk ${CLASS_NAMES.ERROR_MODIFIER}`}>
                *{" "}
              </span>
            )}
            {this.props.label}
          </Label>
        ) : null}
        <>
          {hasIcon ? (
            <div
              style={{
                position: "relative",
              }}
            >
              {this.renderIcon()}
              {this.renderInputComponent(
                this.props.inputType,
                this.props.multiline,
              )}
            </div>
          ) : (
            this.renderInputComponent(
              this.props.inputType,
              this.props.multiline,
            )
          )}
          <ErrorTooltip
            isOpen={this.props.isInvalid && this.props.showError}
            messages={this.props.errorMessages || ""}
            wrapperClassName={CLASS_NAMES.POPOVER_WRAPPER}
            attachTo={this.getInputDomElement()}
          />
        </>
      </ControlGroup>
    );
  }
}

interface InputComponentState {
  showPassword?: boolean;
  localValue?: string;
  displayValue?: string;
  inputFocused?: boolean;
}

const PaddingOverlayWrapper = (props: {
  appMode?: APP_MODE;
  padding: Padding;
  widgetId: string;
  children: React.ReactNode;
}) => {
  if (props.appMode !== APP_MODE.EDIT) {
    return <>{props.children}</>;
  }
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <PaddingOverlay
        appMode={props.appMode}
        padding={props.padding}
        widgetId={props.widgetId}
        layer={Layers.focusedInput + 1}
      />
      <>{props.children}</>
    </div>
  );
};

export default InputComponent;
