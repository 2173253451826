import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { cloneApi } from "../client";
import slice from "../slice";
import { ApiDto } from "../types";

interface CloneApiPayload {
  id: string;
}

function* cloneApiInternal({ id }: CloneApiPayload) {
  const result: ApiDto = yield call(cloneApi, id);
  return result;
}

export const cloneApiSaga = createSaga(cloneApiInternal, "cloneApiSaga", {
  sliceName: "apis",
});

slice.saga(cloneApiSaga, {
  start(state, { payload }) {
    state.loading[payload.id] = true;
    delete state.errors[payload.id];
  },
  success(state, { payload, meta }) {
    state.entities[payload.id] = payload;
    delete state.loading[meta.args.id];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.id] = { error: payload };
    delete state.loading[meta.args.id];
  },
});
