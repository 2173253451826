import { findIndex, isUndefined } from "lodash";
import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { MAX_SAVE_FAILURES } from "legacy/constants/editorConstants";
import { getOrderedActions } from "pages/Editors/ApiEditor/ApiCanvas/utils";
import { EntitiesErrorType } from "store/utils/types";
import invertDependencies from "utils/invertDependencies";
import slice from "./slice";
import type { AppState } from "store/types";
import type { ApiScope } from "utils/dataTree/scope";

type ApiBinding = {
  id: string;
  name: string;
  bindings: string[];
};

export const selectApiCount = createSelector(slice.selector, (state) => {
  return Object.values(state.entities).length;
});

export const selectAllApis = createSelector(
  slice.selector,
  (state) => state.entities,
);

export const selectAllApiNames = createSelector(slice.selector, (state) => {
  return Object.values(state.entities).map((api) => api?.actions?.name ?? "");
});

export const selectAllLoadingApiNames = createSelector(
  slice.selector,
  (state) => {
    const result = Object.entries(state.meta)
      .map(([apiId, meta]) => {
        if (
          state.loading[apiId] ||
          (typeof meta.concurrentRuns === "number" &&
            meta.concurrentRuns > 0) ||
          meta.waitingForEvaluationSince
        ) {
          return state.entities[apiId]?.actions?.name;
        }
        return false;
      })
      .filter(Boolean);
    return result as string[];
  },
);

export const selectStaleApiError = createSelector(slice.selector, (state) =>
  Boolean(state.errors.stale),
);

export const selectApiSavingError = createSelector(slice.selector, (state) =>
  Object.values(state.errors).some(
    (error) => error?.type === EntitiesErrorType.SAVE_ERROR,
  ),
);

export const selectApiSavingFailureMaxReached = createSelector(
  slice.selector,
  (state) =>
    Object.values(state.meta).some(
      (meta) => (meta.savingFailuresCount ?? 0) >= MAX_SAVE_FAILURES,
    ),
);

export const selectApiById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (slice, apiId) => (apiId ? slice.entities[apiId] : undefined),
)((state, apiId) => apiId ?? "undefined");

export const selectApisByIds = createSelector(
  selectAllApis,
  (state: unknown, apiIds: string[]) => apiIds,
  (apis, apiIds) => apiIds.map((apiId) => apis[apiId]),
);

export const selectApiMeta = createSelector(
  slice.selector,
  (state) => state.meta,
);

export const selectApiNameToIdMap = createSelector(
  selectAllApis,
  (apis) =>
    Object.values(apis).reduce(
      (result, api) => ({ ...result, [api?.actions?.name ?? ""]: api.id }),
      {},
    ) as Record<string, string>,
);

export const selectApiMetaById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (state, apiId) => (apiId ? state.meta[apiId] : undefined),
)((state: unknown, apiId: string | undefined) => apiId ?? "undefined");

export const selectActionById = createCachedSelector(
  slice.selector,
  (
    state: unknown,
    apiId: string | undefined,
    actionId: string | undefined,
  ) => ({ apiId, actionId }),
  (slice, { apiId, actionId }) =>
    apiId && actionId
      ? slice.entities[apiId]?.actions?.actions?.[actionId]
      : undefined,
)((state, apiId, actionId) => `${apiId}_${actionId}`);

export const selectApiLoadingById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) =>
    Boolean(state.loading[apiId]) ||
    Boolean((state.meta[apiId]?.concurrentRuns ?? 0) > 0) ||
    Boolean(state.meta[apiId]?.waitingForEvaluationSince),
)((state, apiId) => apiId);

export const selectApiRunCancelledById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (state, apiId) => Boolean(state.meta[apiId]?.cancelled),
)((state, apiId) => apiId);

export const selectApiLoading = createSelector(
  slice.selector,
  (state) => state.loading,
);

export const selectApiExecutionErrorById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (slice, apiId) => slice.meta[apiId]?.executionResult?.context?.error,
)((state, apiId) => apiId);

export const selectApisDirty = createSelector(
  slice.selector,
  (state) =>
    !state.errors.stale &&
    Object.values(state.meta).filter((meta) => meta.dirty || meta.saving)
      .length > 0,
);

export const selectApisSaving = createSelector(slice.selector, (state) =>
  Object.values(state.meta).some((meta) => meta.saving),
);

export const selectApiExtractedBindings = createSelector(
  slice.selector,
  (state) => {
    const idToName = Object.fromEntries(
      Object.values(state.entities).map((api) => [api.id, api?.actions?.name]),
    );
    return Object.fromEntries(
      Object.entries(state.meta).map(([id, api]) => {
        return [
          id,
          {
            id,
            name: idToName[id],
            bindings: api.extractedBindings,
          },
        ];
      }),
    );
  },
);

export const selectApiExtractedBindingsById = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string) => apiId,
  (slice, apiId) => slice.meta[apiId]?.extractedBindings ?? [],
)((state, apiId) => apiId);

export const selectEntityDependencyMap = (state: AppState) =>
  state.legacy.evaluations.dependencies.entityDependencyMap;

const selectEntityDependentNames = createSelector(
  selectEntityDependencyMap,
  (entityDepMap) => {
    return Object.values(entityDepMap).reduce((previous, current) => {
      current.forEach((value) => previous.add(value));
      return previous;
    }, new Set<string>());
  },
);

export const selectPageLoadApis = createSelector(
  slice.selector,
  selectApiExtractedBindings,
  selectEntityDependentNames,
  (state, apiDepMap, entityDepNames) => {
    const apis = Object.values(state.entities);
    // Add Widget dependencies
    const dependentNames = new Set(entityDepNames);
    // Add APIs that are explicitly called
    apis
      .filter((api) => api?.actions?.executeOnPageLoad === true)
      .forEach((api) => {
        dependentNames.add(api?.actions?.name ?? "");
      });

    // Remove APIs that are explicitly not called
    apis
      .filter((api) => api?.actions?.executeOnPageLoad === false)
      .forEach((api) => {
        dependentNames.delete(api?.actions?.name ?? "");
      });

    // Add API dependencies based on if they are depended on by the above
    const apiBindings = Object.fromEntries(
      Object.values(apiDepMap).map((api) => [api.name, api]),
    );

    let apisToCheck: ApiBinding[] = Object.values(apiBindings);
    do {
      const foundDependencies: string[] = [];
      for (const api of apisToCheck) {
        if (dependentNames.has(api?.name)) {
          api?.bindings
            ?.map((binding) => binding.split(".", 1)[0])
            .filter((dep) => !dependentNames.has(dep))
            .filter((depName) => {
              // Allow if it's not an API or if the API is not turned explicitly off.
              const api = state.entities[apiBindings[depName]?.id];
              return !api || api?.actions?.executeOnPageLoad !== false;
            })
            .forEach((dep) => {
              dependentNames.add(dep);
              foundDependencies.push(dep);
            });
        }
      }

      apisToCheck = foundDependencies
        .map((depName) => apiBindings[depName]) // Get the bindings for the dependency
        .filter(Boolean); // Filter out any that are not APIS
    } while (apisToCheck.length > 0);

    // There are three cases that can trigger an API to run on load:
    // 1. If the user has explicitly enabled this
    // 2. Without any user choice, it requires that a component -> API dependency exists
    // 3. Without any user choice, for APIB, it requires that an APIA -> APIB dependency exists and that APIA or an ancestor follows rules 1 or 2
    return apis.filter((api) => {
      return (
        api?.actions?.executeOnPageLoad ||
        (dependentNames.has(api?.actions?.name ?? "") &&
          isUndefined(api?.actions?.executeOnPageLoad))
      );
    });
  },
);

export const selectInverseApiMap = createSelector(
  selectApiExtractedBindings,
  (apiDepMap) => {
    const apis = Object.values(apiDepMap);
    const apisToCheck = apis.map((api) => api.name ?? "");
    const nameToBindings = Object.fromEntries(
      apis.map((api) => [api.name, api.bindings ?? []]),
    );

    return invertDependencies(apisToCheck, nameToBindings);
  },
);

const selectOrderedActionsByApiId = createCachedSelector(selectApiById, (api) =>
  getOrderedActions(api?.actions),
)((state, apiId: string | undefined) => apiId ?? "undefined");

export const selectUserAccessibleScope = createCachedSelector(
  selectApiById,
  selectOrderedActionsByApiId,
  (state: unknown, apiId: string | undefined, actionId: string | undefined) =>
    actionId,
  (api, orderedActions, actionId) => {
    if (!api || !actionId) {
      return;
    }

    const currentActionIdx = findIndex(
      orderedActions,
      (action) => action.id === actionId,
    );

    return {
      apiName: api?.actions?.name,
      previousV1ActionNames: orderedActions
        .slice(0, currentActionIdx)
        .map((action) => action.name),
    } as ApiScope;
  },
)(
  (state, apiId: string | undefined, actionId?: string | undefined) =>
    `${apiId}_${actionId}`,
);

export const selectTestProfile = createCachedSelector(
  slice.selector,
  (state: unknown, apiId: string | undefined) => apiId,
  (slice, apiId) => (apiId ? slice.meta[apiId]?.testProfile : undefined),
)((state, apiId) => apiId ?? "undefined");
