import { IntegrationUpdate } from "@superblocksteam/schemas";
import { IntegrationDto } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { createSaga } from "../../../utils/saga";
import { updateDatasource } from "../client";
import slice from "../slice";

function* updateDatasourceInternal(updated: IntegrationUpdate) {
  const datasource: IntegrationDto = yield call(updateDatasource, updated);
  return datasource;
}

export const updateDatasourceSaga = createSaga(
  updateDatasourceInternal,
  "updateDatasourceSaga",
  { sliceName: "datasources" },
);

slice.saga(updateDatasourceSaga, {
  start(state, { payload }) {
    state.loading[payload.id] = true;
    delete state.errors[payload.id];
  },
  success(state, { payload }) {
    state.entities[payload.id] = state.entities[payload.id] ?? {};
    Object.assign(state.entities[payload.id], { full: payload });
    delete state.loading[payload.id];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.id] = { error: payload };
    delete state.loading[meta.args.id];
  },
});
