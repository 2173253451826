import { call } from "redux-saga/effects";

import { createSaga } from "../../../utils/saga";

import { deleteApiV3 } from "../client";
import slice from "../slice";
import { ApiDto } from "../types";

interface DeleteApiPayload {
  id: string;
  branch: string | undefined;
}

function* deleteApiInternal({ id, branch }: DeleteApiPayload) {
  const result: ApiDto = yield call(deleteApiV3, id, branch);
  return result;
}

export const deleteApiSaga = createSaga(deleteApiInternal, "deleteApiSaga", {
  sliceName: "apis",
});

slice.saga(deleteApiSaga, {
  start(state, { payload }) {
    state.loading[payload.id] = true;
    delete state.errors[payload.id];
  },
  success(state, { payload }) {
    delete state.loading[payload.id];
    delete state.entities[payload.id];
    delete state.meta[payload.id];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.id] = { error: payload };
    state.loading[meta.args.id] = false;
  },
});
