import {
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
} from "@blueprintjs/core";
import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { ControlIcons } from "legacy/icons/ControlIcons";
import VideoComponent, {
  VideoComponentProps,
} from "../../VideoWidget/VideoComponent";
const PlayIcon = styled(ControlIcons.PLAY_VIDEO as AnyStyledComponent)`
  position: relative;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: ${LegacyNamedColors.POMEGRANATE};
      }
    }
  }
`;

const PlayerWrapper = styled.div`
  max-width: 600px;
  max-height: 400px;
`;

const PopoverVideo = (props: VideoComponentProps) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        position={PopoverPosition.AUTO}
        interactionKind={PopoverInteractionKind.CLICK}
        minimal
        usePortal
        enforceFocus={false}
        lazy
        modifiers={{
          flip: {
            options: {
              allowedAutoPlacements: ["right", "left", "bottom", "top"],
              // Default from v1
              padding: 5,
            },
          },
          arrow: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
          },
        }}
        content={
          <PlayerWrapper>
            <VideoComponent url={props.url} />
          </PlayerWrapper>
        }
      >
        <PlayIcon></PlayIcon>
      </Popover>
    </div>
  );
};

export default PopoverVideo;
