import React from "react";
import styled from "styled-components";
import CheckboxComponent, {
  StaticTypographyCheckboxLabelProps,
} from "legacy/widgets/CheckboxWidget/CheckboxComponent";
import {
  SingleCellProperties,
  CompactMode,
  EditProps,
  TABLE_SIZES,
} from "../Constants";
import { CellWrapper } from "../TableStyledWrappers";
import { CellAlignmentTypes } from "../types";

const StyledCellWrapper = styled(CellWrapper)`
  ${(props) =>
    props?.cellProperties?.horizontalAlignment === CellAlignmentTypes.RIGHT
      ? "padding-right: 10px;"
      : props?.cellProperties?.horizontalAlignment === CellAlignmentTypes.CENTER
        ? "padding-left: 19px;"
        : ""};

  > div {
    width: unset !important;
  }

  .checkbox-container {
    display: none;
  }

  .read-mode-component {
    display: flex;
  }

  &[data-is-focused="true"] {
    .read-mode-component {
      display: none;
    }

    .checkbox-container {
      display: flex;
    }
  }

  &:hover:not([data-is-focused="true"]) {
    .read-mode-component {
      display: none;
    }

    .checkbox-container {
      display: flex;
    }
  }
`;

const EditCheckboxCell = (props: {
  editProps: EditProps;
  value: any;
  readModeComponent: JSX.Element;
  compactMode: CompactMode;
  cellProperties: SingleCellProperties;
  isFocused: boolean;
}) => {
  const {
    value,
    editProps,
    readModeComponent,
    isFocused: propsIsFocused,
  } = props;
  const { handleOneClickEdit } = editProps;

  return (
    <StyledCellWrapper
      compactMode={props.compactMode}
      cellProperties={props.cellProperties}
      data-is-focused={propsIsFocused}
      data-test={`edit-checkbox-active-${value}`}
      style={{
        // eslint-disable-next-line no-constant-binary-expression
        height: TABLE_SIZES?.[props.compactMode]?.ROW_HEIGHT - 8 ?? "32px",
      }}
    >
      <div
        className="read-mode-component"
        data-test={`edit-checkbox-${value}`}
        style={{
          height: "100%",
          alignItems: "center",
        }}
      >
        {readModeComponent}
      </div>

      <CheckboxComponent
        isChecked={value}
        isLoading={false}
        onCheckChange={handleOneClickEdit}
        label=""
        widgetId="unused"
        labelProps={StaticTypographyCheckboxLabelProps}
      />
    </StyledCellWrapper>
  );
};

export default EditCheckboxCell;
